import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import "../styles/auth.scss";
import { AppContext } from "../contexts/AppContext";

const Logout = () => {

  const { logout } = useContext(AppContext);
  
  useEffect(() => {
    logout();
  }, []);
  
  return (
    <div className="terms min-w-screen min-h-screen py-10 md:py-16 bg-main">
      <div className="text-center">Signing out...</div>
    </div>
  );
};

export default Logout;
