import React, { Fragment, useContext, useState, useEffect } from 'react';
import AppLayout from '../layouts/AppLayout';
import DataTable from '../components/DataTable';
import { AppContext } from '../contexts/AppContext';
import { Dialog, Transition } from '@headlessui/react'
import { RxCross2 } from 'react-icons/rx';
import { Link } from 'react-router-dom';
import Spinner from "react-activity/dist/Spinner";
import "react-activity/dist/Spinner.css";
import Swal from 'sweetalert2';
import { FaPlus } from "react-icons/fa6";
import "../styles/customers.scss";


const AllCustomers = () => {

const { getcustomers, deletecustomer, isLoading } = useContext(AppContext);

const [customers, setCustomers] = useState([]);
const [statusFilter, setStatusFilter] = useState('');

const handleDelete = async (todelete) => {
  if (!isLoading) {
    try {
      const result = await Swal.fire({
        title: 'Are you sure to delete?',
        text: "You won't be able to undo this action",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Delete'
      });

      if (result.isConfirmed) {
        let response = await deletecustomer(todelete);
        if (response === 'success') {
          fetchCustomers();
        }
      }
    } catch (error) {
      Swal.fire("An Error Occurred", error, "error");
    }
  }
};

const columns = React.useMemo(
  () => [
    { Header: 'Company', accessor: 'company', },
    { Header: 'First Name', accessor: 'name', },
    { Header: 'Last Name', accessor: 'lastname', },
    { Header: 'Email Address', accessor: 'email', },
    { Header: 'Phone Number', accessor: 'phone', },
    { Header: 'Status', accessor: 'status', },
    {
      Header: 'Actions',
      accessor: 'actions', // You can use any accessor name here
      // Define the cell content and actions buttons
      Cell: ({ row }) => (
        <div>
          <Link className='text-edit' to={`/add-customer/${row.original.userid}`}>Edit</Link>
          &nbsp;
          <button className='text-delete' onClick={() => handleDelete(row.original.userid)}>Delete</button>
        </div>
      ),
    },
  ],
  []
);

  const fetchCustomers = async () => {
    const response = await getcustomers(statusFilter);
    setCustomers(response);
  };
  
  useEffect(() => {
    fetchCustomers();
  }, [statusFilter])

  return (
    <AppLayout pageId={"clients"} pageName="Clients" pageBtn={<Link to={'/add-customer'} className="btn btn-new"><FaPlus className='mr-1.5'/> Add New</Link>}>
      <section>
      <div className='flex justify-between'>
        <div className="mb-4">
        </div>
        <div className="mb-4">
          <div className='flex items-center border rounded-lg space-x-2 pl-2'>
            <div><label htmlFor="statusFilter">Filter by Status</label></div>
            <select id="statusFilter" name="statusFilter" className="form-control !w-auto" value={statusFilter} onChange={(e) => setStatusFilter(e.target.value)}>
              <option value="">All</option>
              <option value="Active">Active</option>
              <option value="Inactive">Inactive</option>
              <option value="Under Review">Under Review</option>
              <option value="Rejected">Rejected</option>
            </select>
          </div>
        </div>
      </div>
      { isLoading ? <div className='flex justify-center w-full py-12'><Spinner color='#CCCCCC' size={24}/></div> : 
      <DataTable data={customers} columns={columns} />
      }
      
      </section>
    </AppLayout>
  );
};

export default AllCustomers;
