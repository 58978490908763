import React, { Fragment, useContext, useState, useEffect, useCallback } from "react";
import AppLayout from "../layouts/AppLayout";
import DataTable from "../components/DataTable";
import { AppContext } from "../contexts/AppContext";
import { Link, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { FaPlus } from "react-icons/fa6";
import "../styles/influencers.scss";
import Bounce from "react-activity/dist/Bounce";
import "react-activity/dist/Bounce.css";
import Spinner from "react-activity/dist/Spinner";
import "react-activity/dist/Spinner.css";
import { HiArrowNarrowRight } from "react-icons/hi";
import Modal from "../components/Modal";

import { PiSlidersHorizontal, PiSlidersHorizontalFill } from "react-icons/pi";
import MultiSelect from "../components/MultiSelect"; // Ensure to create this file for custom styles
import "../styles/discover.scss";
import { PiUsers } from "react-icons/pi";
import { FiUsers } from "react-icons/fi";
import { LuArrowUpRight } from "react-icons/lu";
import { HiMiniChevronDown, HiMiniChevronUp } from "react-icons/hi2";
import { TbChecks } from "react-icons/tb";
import { FiSearch } from "react-icons/fi";
import { FaRegHeart, FaHeart } from "react-icons/fa";
import { PiStarFour } from "react-icons/pi";
import { PiPlusCircle } from "react-icons/pi";

const AllInfluencers = () => {
  const { influencerId } = useParams();

  const { getinfluencers, deleteinfluencer, getinfluencer, addinfluencer, updateinfluencer, getlists, getfilteroptions, isLoading } = useContext(AppContext);

  const [influencers, setInfluencers] = useState([]);
  const [tableLoading, settableLoading] = useState(true);
  const [statusFilter, setStatusFilter] = useState("");

  const [influencer, setInfluencer] = useState("");
  const [showEditModal, setshowEditModal] = useState(false);
  const [name, setName] = useState("");
  const [category, setCategory] = useState("");
  const [location, setLocation] = useState("");
  const [instagramUsername, setinstagramUsername] = useState("");
  const [instagramPosts, setinstagramPosts] = useState("");
  const [instagramFollowers, setinstagramFollowers] = useState("");
  const [instagramFollowing, setinstagramFollowing] = useState("");
  const [tiktokUsername, settiktokUsername] = useState("");
  const [tiktokLikes, settiktokLikes] = useState("");
  const [tiktokFollowers, settiktokFollowers] = useState("");
  const [tiktokFollowing, settiktokFollowing] = useState("");
  const [youtubeUsername, setyoutubeUsername] = useState("");
  const [youtubeVideos, setyoutubeVideos] = useState("");
  const [youtubeViews, setyoutubeViews] = useState("");
  const [youtubeSubscribers, setyoutubeSubscribers] = useState("");
  const [twitchUsername, settwitchUsername] = useState("");
  const [twitchFollowers, settwitchFollowers] = useState("");
  const [twitchAvgViews, settwitchAvgViews] = useState("");
  const [twitchSubscribers, settwitchSubscribers] = useState("");
  const [status, setStatus] = useState("");
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [existingImage, setExistingImage] = useState(null);
  const [error, setError] = useState("");
  const [modalopen, setModalopen] = useState(false);
  const [reviewaction, setReviewaction] = useState("");
  const [toEdit, settoEdit] = useState('');
  const [toDelete, settoDelete] = useState('');
  const [eformLoading, seteFormLoading] = useState(false);

  const [fetchingLoading, setfetchingLoading] = useState(true);
  const [lists, setLists] = useState([]);
  const [filters, setFilters] = useState({
    tier: [],
    location: [],
    category: [],
    gender: [],
    platform: [],
  });
  const [infId, setInfId] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [infLoading, setinfLoading] = useState(false);
  const [offset, setOffset] = useState(0);
  const [sharedAlready, setsharedAlready] = useState(0);
  const [savedAlready, setsavedAlready] = useState(0);
  const [loadingMore, setLoadingMore] = useState(false);
  const [showListsDropdown, setShowListsDropdown] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [showNewModal, setshowNewModal] = useState(false);
  const [listName, setListName] = useState('');
  const [isFormChanged, setIsFormChanged] = useState(false);
  const [formLoading, setFormLoading] = useState(false);

  const [options, setOptions] = useState({
    tier: [
      { value: 'Tier 1', label: 'Tier 1' },
      { value: 'Tier 2', label: 'Tier 2' },
      { value: 'Tier 3', label: 'Tier 3' },
    ],
    location: [
      { value: 'Sweden', label: 'Sweden' },
      { value: 'USA', label: 'USA' },
      { value: 'Canada', label: 'Canada' },
    ],
    category: [
      { value: 'Fashion', label: 'Fashion' },
      { value: 'Technology', label: 'Technology' },
      { value: 'Food', label: 'Food' },
    ],
    gender: [
      { value: 'Male', label: 'Male' },
      { value: 'Female', label: 'Female' },
    ],
    platform: [
      { value: 'TikTok', label: 'TikTok' },
      { value: 'Instagram', label: 'Instagram' },
      { value: 'YouTube', label: 'YouTube' },
      { value: 'Twitch', label: 'Twitch' },
    ],
  });

  const [searchInput, setSearchInput] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");

  const limit = 20;

  const fetchOptions = async () => {
    try {
      const response = await getfilteroptions();
      if (response) {
        const validLocations = response.location.filter(
          (loc) => loc.value && loc.label
        );
  
        setOptions((prevOptions) => ({
          ...prevOptions,
          location: validLocations,
        }));
      }
    } catch (error) {
      console.error("Error fetching options:", error);
    }
  };

  useEffect(() => {
    fetchOptions();
  }, []);

  const handleFilterChange = (filterType, selectedOptions) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterType]: selectedOptions,
    }));
  };

  const removeSelectedOption = (filterType, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterType]: prevFilters[filterType].filter((option) => option !== value),
    }));
  };

  const genericSearch = (items, searchTerm) => {
    if (!searchTerm) {
      return items;
    }

    const lowerCaseSearchTerm = searchTerm.toLowerCase();

    return items.filter((item) =>
      Object.values(item).some((value) =>
        value && value.toString().toLowerCase().includes(lowerCaseSearchTerm)
      )
    );
  };

  const navigate = useNavigate();

  const handleDelete = async (todelete) => {
    if (!isLoading) {
      try {
        const result = await Swal.fire({
          title: "Are you sure to delete?",
          text: "You won't be able to undo this action",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, Delete",
        });

        if (result.isConfirmed) {
          let response = await deleteinfluencer(todelete);
          if (response === "success") {
            setInfluencers(prevInfluencers => 
              prevInfluencers.filter(inf => inf.influencerid !== todelete)
            );
            
          }
        }
      } catch (error) {
        Swal.fire("An Error Occurred", error, "error");
      }
    }
  };

  const columns = React.useMemo(
    () => [
      { Header: "Influencer", accessor: "name", 
        Cell: ({ row }) => (
          <>
            <div className="tdname">
              <div>{ row.original.profileimage !== '' ? <img src={`data:image/png;base64,${row.original.profileimage}`}/> : <div className="img-placeholder"><span>{row.original.name[0]}</span></div> }</div>
              <div><span className="font-medium">{row.original.name}</span><br/><span>@{row.original.instagram}</span></div>
            </div>
          </>
        ),
      },
      { Header: "Location", accessor: "location" },
      { Header: "Category", accessor: "category" },
      {
        Header: "Actions",
        accessor: "actions",
        Cell: ({ row }) => (
          <div>
            <Link className="text-edit" onClick={(e) => handleEdit(e, row.original.influencerid)}>
              Edit
            </Link>
            &nbsp;
            <button className="text-delete" onClick={() => handleDelete(row.original.influencerid)}>
              Delete
            </button>
          </div>
        ),
      },
    ],
    []
  );
  
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchInput);
    }, 300);
  
    return () => {
      clearTimeout(handler);
    };
  }, [searchInput]);

  const fetchInfluencers = useCallback(
    async (reset = false) => {
      if (!hasMore && !reset) return;
  
      setLoadingMore(true);
  
      const currentOffset = reset ? 0 : offset;
      const response = await getinfluencers({ ...filters, searchTerm: debouncedSearchTerm }, limit, currentOffset);
  
      if (response.length > 0) {
        setInfluencers((prev) => (reset ? response : [...prev, ...response]));
        setOffset(currentOffset + limit);
      } else {
        setHasMore(false);
      }
  
      setfetchingLoading(false);
      setLoadingMore(false);
    },
    [getinfluencers, filters, debouncedSearchTerm, limit, offset, hasMore]
  );

  // const debouncedFetchInfluencers = useCallback(debounce(fetchInfluencers, 300), [searchTerm]);

  // useEffect(() => {
  //   debouncedFetchInfluencers();
  // }, [debouncedFetchInfluencers]);

  // useEffect(() => {
  //   setInfluencers([]);
  //   setOffset(0);
  //   setHasMore(true);
  //   fetchInfluencers();
  // }, [debouncedSearchTerm, filters]);

  // Effect to handle filters and search term changes
  useEffect(() => {
    setHasMore(true); // Reset pagination
    fetchInfluencers(true); // Pass reset=true to start from offset 0
  }, [debouncedSearchTerm, filters]);


  useEffect(() => {
    settableLoading(true);
    fetchInfluencers();
  }, [statusFilter]);

  useEffect(() => {
    const fetchInfluencer = async () => {
      const response = await getinfluencer(influencerId);
      if (response) {
        setInfluencer(response[0]);
        setName(response[0].name);
        setLocation(response[0].location);
        setCategory(response[0].category);
        setinstagramUsername(response[0].instagram);
        setinstagramPosts(response[0].instagramposts);
        setinstagramFollowers(response[0].instagramfollowers);
        setinstagramFollowing(response[0].instagramfollowing);
        settiktokUsername(response[0].tiktok);
        settiktokLikes(response[0].tiktoklikes);
        settiktokFollowers(response[0].tiktokfollowers);
        settiktokFollowing(response[0].tiktokfollowing);
        setyoutubeUsername(response[0].youtube);
        setyoutubeVideos(response[0].youtubevideos);
        setyoutubeViews(response[0].youtubeviews);
        setyoutubeSubscribers(response[0].youtubesubscribers);
        settwitchUsername(response[0].twitch);
        settwitchFollowers(response[0].twitchfollowers);
        settwitchAvgViews(response[0].twitchavgviews);
        settwitchSubscribers(response[0].twitchsubscribers);
        setStatus(response[0].status);
        setExistingImage(`data:image/png;base64,${response[0].profileimage}`);
      }
    };

    if (influencerId) {
      fetchInfluencer();
    }
  }, [influencerId]);

  useEffect(() => {
    const fetchInfluencer = async () => {
      if(infId != ''){
        setinfLoading(true);
        setsharedAlready(0);
        setsavedAlready(0);
        const response = await getinfluencer(infId);
        setInfluencer(response[0]);
        setinfLoading(false);
      }
    };

    fetchInfluencer();
  }, [infId]);

  const handleScroll = (e) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom && !loadingMore) {
      fetchInfluencers();
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImage(file);
    if (file) {
      const reader = new FileReader();
      reader.onload = () => setImagePreview(reader.result);
      reader.readAsDataURL(file);
    } else {
      setImagePreview(null);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isLoading === false) {
      try {
        if (name !== "") {
          setError("");
          let response = "";
          if (toEdit != "") {
            seteFormLoading(true);
            
            response = await updateinfluencer({ name, category, location, instagramUsername, instagramPosts, instagramFollowers, instagramFollowing, tiktokUsername, tiktokLikes, tiktokFollowers, tiktokFollowing, youtubeUsername, youtubeVideos, youtubeViews, youtubeSubscribers, twitchUsername, twitchFollowers, twitchAvgViews, twitchSubscribers, image, influencerId: toEdit });
            if (response === "success") {
              settoEdit('');
              setshowEditModal(false);
              seteFormLoading(false);
              setInfluencer('');
              setName('');
              setLocation('');
              setCategory('');
              setinstagramUsername('');
              setinstagramPosts('');
              setinstagramFollowers('');
              setinstagramFollowing('');
              settiktokUsername('');
              settiktokLikes('');
              settiktokFollowers('');
              settiktokFollowing('');
              setyoutubeUsername('');
              setyoutubeVideos('');
              setyoutubeViews('');
              setyoutubeSubscribers('');
              settwitchUsername('');
              settwitchFollowers('');
              settwitchAvgViews('');
              settwitchSubscribers('');
              setStatus('');
              setExistingImage('');

              setInfluencers(prevInfluencers => {
                return prevInfluencers.map(inf => {
                  if (inf.influencerid === toEdit) {
                    return { ...inf, influencerid: inf.influencerid, name: name, instagram: instagramUsername, tiktok: tiktokUsername, youtube: youtubeUsername, twitch: twitchUsername, location: location, category: category };
                  }
                  return inf;
                });
              });



            }
          } else {
            response = await addinfluencer({ name, category, location, instagramUsername, instagramPosts, instagramFollowers, instagramFollowing, tiktokUsername, tiktokLikes, tiktokFollowers, tiktokFollowing, youtubeUsername, youtubeVideos, youtubeViews, youtubeSubscribers, twitchUsername, twitchFollowers, twitchAvgViews, twitchSubscribers, image});
            if (response.message === "success") {
              setshowNewModal(false);
              setFormLoading(false);
              setInfluencer('');
              setName('');
              setLocation('');
              setCategory('');
              setinstagramUsername('');
              setinstagramPosts('');
              setinstagramFollowers('');
              setinstagramFollowing('');
              settiktokUsername('');
              settiktokLikes('');
              settiktokFollowers('');
              settiktokFollowing('');
              setyoutubeUsername('');
              setyoutubeVideos('');
              setyoutubeViews('');
              setyoutubeSubscribers('');
              settwitchUsername('');
              settwitchFollowers('');
              settwitchAvgViews('');
              settwitchSubscribers('');
              setStatus('');
              setExistingImage('');

              setInfluencers(prevInfluencers => {
                return [
                  ...prevInfluencers, 
                  {
                    influencerid: response.influencerid, 
                    name: name, 
                    instagram: instagramUsername,  
                    tiktok: tiktokUsername,        
                    youtube: youtubeUsername,      
                    twitch: twitchUsername,        
                    location: location,            
                    category: category              
                  }
                ];
              });
              
            }
          }
        } else {
          setError("Name is required");
          return;
        }
      } catch (error) {
        setError("Update failed: " + error);
      }
    }
  };

  const handleEdit = async (i) => {
    settoEdit(i);
    // seteFormLoading(true);
    setshowEditModal(true);
    const response = await getinfluencer(i);
    if (response) {
      setInfluencer(response[0]);
      setName(response[0].name);
      setLocation(response[0].location);
      setCategory(response[0].category);
      setinstagramUsername(response[0].instagram);
      setinstagramPosts(response[0].instagramposts);
      setinstagramFollowers(response[0].instagramfollowers);
      setinstagramFollowing(response[0].instagramfollowing);
      settiktokUsername(response[0].tiktok);
      settiktokLikes(response[0].tiktoklikes);
      settiktokFollowers(response[0].tiktokfollowers);
      settiktokFollowing(response[0].tiktokfollowing);
      setyoutubeUsername(response[0].youtube);
      setyoutubeVideos(response[0].youtubevideos);
      setyoutubeViews(response[0].youtubeviews);
      setyoutubeSubscribers(response[0].youtubesubscribers);
      settwitchUsername(response[0].twitch);
      settwitchFollowers(response[0].twitchfollowers);
      settwitchAvgViews(response[0].twitchavgviews);
      settwitchSubscribers(response[0].twitchsubscribers);
      setStatus(response[0].status);
      setExistingImage(`data:image/png;base64,${response[0].profileimage}`);
    }
  };
  
  const isAnyFilterApplied = () => {
    return Object.values(filters).some((filterOptions) => filterOptions.length > 0);
  };

  return (
    <AppLayout pageId="discover" pageName="Influencers" pageBtn={<Link onClick={() => setshowNewModal(true)} className="btn btn-new"><FaPlus className='mr-1.5'/> Add New</Link>}>
      <section>
      {isLoading ? (
          <div className="flex justify-center w-full py-12">
            <Spinner color="#CCCCCC" size={24} />
          </div>
          ) : (
          <>
        { showNewModal && 
        <Modal heading="New Influencer" classes="!w-11/12" isOpen={showNewModal} onClose={() => setshowNewModal(false)}>
        <form onSubmit={handleSubmit} method="post" encType="multipart/form-data" className={`flex flex-row flex-wrap w-full ${status == "Under Review" ? "pointer-events-none" : ""}`}>
          <div className="w-2/4 p-2">
            <label htmlFor="name">Influencer Name <span className="text-red-500">*</span></label>
            <input id="name" name="name" required type="text" className="form-control" maxLength={50} value={name} onChange={(e) => setName(e.target.value)} />
          </div>
          <div className="w-1/4 p-2">
            <label htmlFor="location">Location</label>
            <input id="location" name="location" type="text" className="form-control" placeholder="Country, State" value={location} onChange={(e) => setLocation(e.target.value)} maxLength={255} />
          </div>
          <div className="w-1/4 p-2">
            <label htmlFor="name">Category</label>
            <input id="category" name="category" type="text" className="form-control" value={category} onChange={(e) => setCategory(e.target.value)} />
          </div>
          <div className="w-2/4">
            <h6 className="text-base text-main uppercase font-semibold mt-3 -mb-2">Instagram</h6>
            <div className="flex flex-row flex-wrap w-full">
              <div className="w-1/4 p-2">
              <label htmlFor="instagramUsername">Username</label>
                <input id="instagramUsername" name="instagramUsername" type="text" className="form-control" value={instagramUsername} onChange={(e) => setinstagramUsername(e.target.value)}/>
              </div>
              <div className="w-1/4 p-2">
              <label htmlFor="instagramPosts">Posts</label>
                <input id="instagramPosts" name="instagramPosts" type="text" className="form-control" value={instagramPosts} onChange={(e) => setinstagramPosts(e.target.value)} />
              </div>
              <div className="w-1/4 p-2">
              <label htmlFor="instagramFollowers">Followers</label>
                <input id="instagramFollowers" name="instagramFollowers" type="text" className="form-control" value={instagramFollowers} onChange={(e) => setinstagramFollowers(e.target.value)} />
              </div>
              <div className="w-1/4 p-2">
                <label htmlFor="instagramFollowing">Following</label>
                <input id="instagramFollowing" name="instagramFollowing" type="text" className="form-control" value={instagramFollowing} onChange={(e) => setinstagramFollowing(e.target.value)} />
              </div>
            </div>
          </div>
          <div className="w-2/4">
            <h6 className="text-base text-main uppercase font-semibold mt-3 -mb-2">Tiktok</h6>
            <div className="flex flex-row flex-wrap w-full">
              <div className="w-1/4 p-2">
              <label htmlFor="tiktokUsername">Username</label>
                <input id="tiktokUsername" name="tiktokUsername" type="text" className="form-control" value={tiktokUsername} onChange={(e) => settiktokUsername(e.target.value)} />
              </div>
              <div className="w-1/4 p-2">
              <label htmlFor="tiktokLikes">Likes</label>
                <input id="tiktokLikes" name="tiktokLikes" type="text" className="form-control" value={tiktokLikes} onChange={(e) => settiktokLikes(e.target.value)} />
              </div>
              <div className="w-1/4 p-2">
              <label htmlFor="tiktokFollowers">Followers</label>
                <input id="tiktokFollowers" name="tiktokFollowers" type="text" className="form-control" value={tiktokFollowers} onChange={(e) => settiktokFollowers(e.target.value)} />
              </div>
              <div className="w-1/4 p-2">
                <label htmlFor="tiktokFollowing">Following</label>
                <input id="tiktokFollowing" name="tiktokFollowing" type="text" className="form-control" value={tiktokFollowing} onChange={(e) => settiktokFollowing(e.target.value)} />
              </div>
            </div>
          </div>
          <div className="w-2/4">
            <h6 className="text-base text-main uppercase font-semibold mt-3 -mb-2">Youtube</h6>
            <div className="flex flex-row flex-wrap w-full">
              <div className="w-1/4 p-2">
              <label htmlFor="youtubeUsername">Username</label>
                <input id="youtubeUsername" name="youtubeUsername" type="text" className="form-control" value={youtubeUsername} onChange={(e) => setyoutubeUsername(e.target.value)} />
              </div>
              <div className="w-1/4 p-2">
              <label htmlFor="youtubeVideos">Videos</label>
                <input id="youtubeVideos" name="youtubeVideos" type="text" className="form-control" value={youtubeVideos} onChange={(e) => setyoutubeVideos(e.target.value)} />
              </div>
              <div className="w-1/4 p-2">
              <label htmlFor="youtubeViews">Views</label>
                <input id="youtubeViews" name="youtubeViews" type="text" className="form-control" value={youtubeViews} onChange={(e) => setyoutubeViews(e.target.value)} />
              </div>
              <div className="w-1/4 p-2">
                <label htmlFor="youtubeSubscribers">Subscribers</label>
                <input id="youtubeSubscribers" name="youtubeSubscribers" type="text" className="form-control" value={youtubeSubscribers} onChange={(e) => setyoutubeSubscribers(e.target.value)} />
              </div>
            </div>
          </div>
          <div className="w-2/4">
            <h6 className="text-base text-main uppercase font-semibold mt-3 -mb-2">Twitch</h6>
            <div className="flex flex-row flex-wrap w-full">
              <div className="w-1/4 p-2">
              <label htmlFor="twitchUsername">Username</label>
                <input id="twitchUsername" name="twitchUsername" type="text" className="form-control" value={twitchUsername} onChange={(e) => settwitchUsername(e.target.value)} />
              </div>
              <div className="w-1/4 p-2">
              <label htmlFor="twitchAvgViews">Avg. Views</label>
                <input id="twitchAvgViews" name="twitchAvgViews" type="text" className="form-control" value={twitchAvgViews} onChange={(e) => settwitchAvgViews(e.target.value)} />
              </div>
              <div className="w-1/4 p-2">
                <label htmlFor="twitchSubscribers">Subscribers</label>
                <input id="twitchSubscribers" name="twitchSubscribers" type="text" className="form-control" value={twitchSubscribers} onChange={(e) => settwitchSubscribers(e.target.value)} />
              </div>
              <div className="w-1/4 p-2">
              <label htmlFor="twitchFollowers">Followers</label>
                <input id="twitchFollowers" name="twitchFollowers" type="text" className="form-control" value={twitchFollowers} onChange={(e) => settwitchFollowers(e.target.value)} />
              </div>
            </div>
          </div>
          <div className="w-2/4 p-2">
            <label htmlFor="image">Profile Image</label>
            <div className="border border-[#c9c9c9] p-2 rounded-lg bg-white">
              <input type="file" accept="image/*" id="image" name="image" className="w-full pointer !rounded-none" onChange={handleImageChange} />
            </div>
            {imagePreview && (
              <div className="mt-4">
                <label>Preview:</label>
                <img src={imagePreview} alt="Selected" style={{ width: "150px", height: "150px" }} />
              </div>
            )}
            {!imagePreview && existingImage && (
              <div className="mt-4">
                <label>Current Image:</label>
                <img src={existingImage} alt="Current" style={{ width: "150px", height: "150px" }} />
              </div>
            )}
          </div>
          <div className="px-2 w-full">
            {error !== "" ? <div className="text-left text-red-500 font-semibold text-md mb-2 tracking-wide">{error}</div> : <div className="py-4"></div>}
              <div className="flex space-x-3">
                <button type="submit" className="btn-submit" disabled={isLoading}>
                  <span>Submit</span>
                  <span>{isLoading === false ? <HiArrowNarrowRight className="ml-3"/> : <Bounce className="ml-3" animating={isLoading} />}</span>
                </button>
              </div>
          </div>
        </form>
        </Modal>
        }
        { showEditModal && 
        <Modal heading="Edit Influencer" classes="!w-11/12" isOpen={showEditModal} onClose={() => setshowEditModal(false)}>
        <form onSubmit={handleSubmit} method="post" encType="multipart/form-data" className={`flex flex-row flex-wrap w-full ${status == "Under Review" ? "pointer-events-none" : ""}`}>
          <div className="w-2/4 p-2">
            <label htmlFor="name">Influencer Name <span className="text-red-500">*</span></label>
            <input id="name" name="name" required type="text" className="form-control" maxLength={50} value={name} onChange={(e) => setName(e.target.value)} />
          </div>
          <div className="w-1/4 p-2">
            <label htmlFor="location">Location</label>
            <input id="location" name="location" type="text" className="form-control" placeholder="Country, State" value={location} onChange={(e) => setLocation(e.target.value)} maxLength={255} />
          </div>
          <div className="w-1/4 p-2">
            <label htmlFor="name">Category</label>
            <input id="category" name="category" type="text" className="form-control" value={category} onChange={(e) => setCategory(e.target.value)} />
          </div>
          <div className="w-2/4">
            <h6 className="text-base text-main uppercase font-semibold mt-3 -mb-2">Instagram</h6>
            <div className="flex flex-row flex-wrap w-full">
              <div className="w-1/4 p-2">
              <label htmlFor="instagramUsername">Username</label>
                <input id="instagramUsername" name="instagramUsername" type="text" className="form-control" value={instagramUsername} onChange={(e) => setinstagramUsername(e.target.value)}/>
              </div>
              <div className="w-1/4 p-2">
              <label htmlFor="instagramPosts">Posts</label>
                <input id="instagramPosts" name="instagramPosts" type="text" className="form-control" value={instagramPosts} onChange={(e) => setinstagramPosts(e.target.value)} />
              </div>
              <div className="w-1/4 p-2">
              <label htmlFor="instagramFollowers">Followers</label>
                <input id="instagramFollowers" name="instagramFollowers" type="text" className="form-control" value={instagramFollowers} onChange={(e) => setinstagramFollowers(e.target.value)} />
              </div>
              <div className="w-1/4 p-2">
                <label htmlFor="instagramFollowing">Following</label>
                <input id="instagramFollowing" name="instagramFollowing" type="text" className="form-control" value={instagramFollowing} onChange={(e) => setinstagramFollowing(e.target.value)} />
              </div>
            </div>
          </div>
          <div className="w-2/4">
            <h6 className="text-base text-main uppercase font-semibold mt-3 -mb-2">Tiktok</h6>
            <div className="flex flex-row flex-wrap w-full">
              <div className="w-1/4 p-2">
              <label htmlFor="tiktokUsername">Username</label>
                <input id="tiktokUsername" name="tiktokUsername" type="text" className="form-control" value={tiktokUsername} onChange={(e) => settiktokUsername(e.target.value)} />
              </div>
              <div className="w-1/4 p-2">
              <label htmlFor="tiktokLikes">Likes</label>
                <input id="tiktokLikes" name="tiktokLikes" type="text" className="form-control" value={tiktokLikes} onChange={(e) => settiktokLikes(e.target.value)} />
              </div>
              <div className="w-1/4 p-2">
              <label htmlFor="tiktokFollowers">Followers</label>
                <input id="tiktokFollowers" name="tiktokFollowers" type="text" className="form-control" value={tiktokFollowers} onChange={(e) => settiktokFollowers(e.target.value)} />
              </div>
              <div className="w-1/4 p-2">
                <label htmlFor="tiktokFollowing">Following</label>
                <input id="tiktokFollowing" name="tiktokFollowing" type="text" className="form-control" value={tiktokFollowing} onChange={(e) => settiktokFollowing(e.target.value)} />
              </div>
            </div>
          </div>
          <div className="w-2/4">
            <h6 className="text-base text-main uppercase font-semibold mt-3 -mb-2">Youtube</h6>
            <div className="flex flex-row flex-wrap w-full">
              <div className="w-1/4 p-2">
              <label htmlFor="youtubeUsername">Username</label>
                <input id="youtubeUsername" name="youtubeUsername" type="text" className="form-control" value={youtubeUsername} onChange={(e) => setyoutubeUsername(e.target.value)} />
              </div>
              <div className="w-1/4 p-2">
              <label htmlFor="youtubeVideos">Videos</label>
                <input id="youtubeVideos" name="youtubeVideos" type="text" className="form-control" value={youtubeVideos} onChange={(e) => setyoutubeVideos(e.target.value)} />
              </div>
              <div className="w-1/4 p-2">
              <label htmlFor="youtubeViews">Views</label>
                <input id="youtubeViews" name="youtubeViews" type="text" className="form-control" value={youtubeViews} onChange={(e) => setyoutubeViews(e.target.value)} />
              </div>
              <div className="w-1/4 p-2">
                <label htmlFor="youtubeSubscribers">Subscribers</label>
                <input id="youtubeSubscribers" name="youtubeSubscribers" type="text" className="form-control" value={youtubeSubscribers} onChange={(e) => setyoutubeSubscribers(e.target.value)} />
              </div>
            </div>
          </div>
          <div className="w-2/4">
            <h6 className="text-base text-main uppercase font-semibold mt-3 -mb-2">Twitch</h6>
            <div className="flex flex-row flex-wrap w-full">
              <div className="w-1/4 p-2">
              <label htmlFor="twitchUsername">Username</label>
                <input id="twitchUsername" name="twitchUsername" type="text" className="form-control" value={twitchUsername} onChange={(e) => settwitchUsername(e.target.value)} />
              </div>
              <div className="w-1/4 p-2">
              <label htmlFor="twitchAvgViews">Avg. Views</label>
                <input id="twitchAvgViews" name="twitchAvgViews" type="text" className="form-control" value={twitchAvgViews} onChange={(e) => settwitchAvgViews(e.target.value)} />
              </div>
              <div className="w-1/4 p-2">
                <label htmlFor="twitchSubscribers">Subscribers</label>
                <input id="twitchSubscribers" name="twitchSubscribers" type="text" className="form-control" value={twitchSubscribers} onChange={(e) => settwitchSubscribers(e.target.value)} />
              </div>
              <div className="w-1/4 p-2">
              <label htmlFor="twitchFollowers">Followers</label>
                <input id="twitchFollowers" name="twitchFollowers" type="text" className="form-control" value={twitchFollowers} onChange={(e) => settwitchFollowers(e.target.value)} />
              </div>
            </div>
          </div>
          <div className="w-2/4 p-2">
            <label htmlFor="image">Profile Image</label>
            <div className="border border-[#c9c9c9] p-2 rounded-lg bg-white">
              <input type="file" accept="image/*" id="image" name="image" className="w-full pointer !rounded-none" onChange={handleImageChange} />
            </div>
            {imagePreview && (
              <div className="mt-4">
                <label>Preview:</label>
                <img src={imagePreview} alt="Selected" style={{ width: "150px", height: "150px" }} />
              </div>
            )}
            {!imagePreview && existingImage && (
              <div className="mt-4">
                <label>Current Image:</label>
                <img src={existingImage} alt="Current" style={{ width: "150px", height: "150px" }} />
              </div>
            )}
          </div>
          <div className="px-2 w-full">
            {error !== "" ? <div className="text-left text-red-500 font-semibold text-md mb-2 tracking-wide">{error}</div> : <div className="py-4"></div>}
              <div className="flex space-x-3">
                <button type="submit" className="btn-submit" disabled={isLoading}>
                  <span>{toEdit ? 'Save Changes' : 'Submit'}</span>
                  <span>{isLoading === false ? <HiArrowNarrowRight className="ml-3"/> : <Bounce className="ml-3" animating={isLoading} />}</span>
                </button>
                { !eformLoading && <button type="button" className="btn-cancel" onClick={() => setshowEditModal(false)}> Cancel </button> }
              </div>
          </div>
        </form>
        </Modal>
        }

        
        <div className="space-y-5">
            <div className="flex space-x-8">
              <div className={`transition-all ${infId != '' ? 'w-4/6' : 'w-full'}`}>
                <div className="section-left flex flex-col">
                  <div className="inf-filters">
                    <div className="flex space-x-5">
                      <div className="search flex-1">
                        {/* <img src="/images/icons/svgs/search-normal.svg" width="15" alt="Search"/> */}
                        <div className="absolute inset-y-0 start-0 flex items-center ps-4 pointer-events-none">
                        <FiSearch color="#999"/>
                        </div>
                        <input
                          className="form-controlx"
                          placeholder="Find by name, country, tier, etc."
                          value={searchInput}
                          onChange={(e) => setSearchInput(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="filters mt-4">
                      <div className="flex flex-wrap justify-start items-center filters-select space-x-10">
                        <div>
                          <PiSlidersHorizontal size={24} color={'#C2C2C2'}/>
                        </div>
                        <MultiSelect
                          options={options.tier}
                          selectedOptions={filters.tier}
                          setSelectedOptions={(selectedOptions) => handleFilterChange("tier", selectedOptions)}
                          placeholder="Tier"
                        />
                        <MultiSelect
                          options={options.location}
                          selectedOptions={filters.location}
                          setSelectedOptions={(selectedOptions) => handleFilterChange("location", selectedOptions)}
                          placeholder="Location"
                        />
                        <MultiSelect
                          options={options.category}
                          selectedOptions={filters.category}
                          setSelectedOptions={(selectedOptions) => handleFilterChange("category", selectedOptions)}
                          placeholder="Category"
                        />
                        <MultiSelect
                          options={options.gender}
                          selectedOptions={filters.gender}
                          setSelectedOptions={(selectedOptions) => handleFilterChange("gender", selectedOptions)}
                          placeholder="Gender"
                        />
                        <MultiSelect
                          options={options.platform}
                          selectedOptions={filters.platform}
                          setSelectedOptions={(selectedOptions) => handleFilterChange("platform", selectedOptions)}
                          placeholder="Platform"
                        />
                      </div>
                    </div>
                    <div className="flex flex-wrap justify-start items-center">
                      { selectedOptions.length > 0 && 
                      <div className="mr-4 mt-[10px]">
                        <PiSlidersHorizontalFill size={24} color={'#C2C2C2'}/>
                      </div>
                      }
                      {/* <div className="selected-tags">
                        {selectedOptions.map(option => (
                          <div key={option} className="tag">
                            {option}
                            <span className="remove" onClick={() => setSelectedOptions(selectedOptions.filter(o => o !== option))}>
                              x
                            </span>
                          </div>
                        ))}
                      </div> */}

                      {isAnyFilterApplied() && (
                        <div className="mr-4 mt-[10px]">
                          <PiSlidersHorizontalFill size={24} color={'#C2C2C2'} />
                        </div>
                      )}
                      <div className="flex flex-wrap justify-start items-center">
                        {Object.entries(filters).map(([filterType, selectedOptions]) =>
                          selectedOptions.length > 0 ? (
                            <div key={filterType} className="selected-filter-group selected-tags mr-4">
                              {/* <h4 className="font-medium text-sm mb-2">{filterType}</h4> */}
                              {selectedOptions.map((option) => (
                                <div key={option} className="tag">
                                  {option}
                                  <span
                                    className="remove"
                                    onClick={() => removeSelectedOption(filterType, option)}
                                  >
                                    x
                                  </span>
                                </div>
                              ))}
                            </div>
                          ) : null
                        )}
                      </div>

                    </div>
                  </div>
                  <div className="inf-table-wrapper flex-1" onScroll={handleScroll}>
                    <table className="inf-table w-full">
                      <thead className="text-sm text-neutral-400">
                        <tr className="border-b-2 border-neutral-300">
                          <th scope="col"> Influencer </th>
                          <th scope="col"> Profiles </th>
                          <th scope="col"> Location </th>
                          <th scope="col"> Followers </th>
                          <th scope="col"> Likes </th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {fetchingLoading ? (
                          <tr>
                            <td colSpan="5" className="!py-10">
                              <Spinner className="mx-auto" color="#CCCCCC" size={24} />
                            </td>
                          </tr>
                        )
                        : 
                        <>
                        { influencers.length > 0 ? 
                        <>
                        { influencers.map((inf, index) => { 
                          const locationParts = inf.location ? inf.location.split(',') : [];
                          return(
                            <tr key={index} className={`${infId == inf.influencerid && 'active-inf'}`} onClick={() => setInfId(inf.influencerid)}>
                              <td className="name">
                                  <div>{ inf.profileimage !== '' ? <img className="profile" src={`data:image/png;base64,${inf.profileimage}`}/> : <div className="img-placeholder"><span>{inf.name[0]}</span></div> }</div>
                                  <div>{inf.name}<br/><span>@{inf.instagram}</span></div>
                              </td>
                              <td className="profiles">
                                <div className="flex space-x-2 justify-center">
                                  {inf.tiktok != '' && <img src="/images/icons/tiktok.png"/>}
                                  {inf.instagram != '' && <img src="/images/icons/instagram.png"/>}
                                  {inf.youtube != '' && <img src="/images/icons/youtube.png"/>}
                                  {inf.twitch != '' && <img src="/images/icons/twitch.png"/>}
                                </div>
                              </td>
                              <td className="location">{locationParts.length > 0 && <span>{locationParts[0]}</span>} {locationParts.length > 1 && <span>{locationParts[1]}</span>}</td>
                              <td className="followers">{inf.tiktokfollowers}</td>
                              <td className="likes">{inf.tiktoklikes}</td>
                              <td>
                                <div>
                                  <Link className="text-edit text-sm" onClick={(e) => handleEdit(inf.influencerid)}>
                                    Edit
                                  </Link>
                                  &nbsp;
                                  <button className="text-delete text-sm" onClick={() => handleDelete(inf.influencerid)}>
                                    Delete
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ) 
                          }) 
                        }
                        {loadingMore && (
                          <tr>
                            <td colSpan="5" className="!py-10">
                              <Spinner className="mx-auto" color="#CCCCCC" size={24} />
                            </td>
                          </tr>
                        )}
                        </>
                        :
                        <tr>
                            <td colSpan="5" className="!text-center !py-10">
                                <h6 className="text-base font-light text-neutral-400">no records found</h6>
                            </td>
                        </tr>
                        }
                        </>
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              { infId != '' && 
              <div className={`w-2/6`}>
                <div class="inf-card">
                  { infLoading ? <div className="flex justify-center w-full py-12"> <Spinner color="#CCCCCC" size={24} /> </div> : 
                  <>
                  <div className="w-full flex flex-1 flex-col justify-between">
                  <div>
                  <div class="text-center mt-5">
                      <img src={`data:image/png;base64,${influencer.profileimage}`} alt="" class="profile"/>
                      <h5 class="title">{influencer.name}</h5>
                      <div className="flex justify-center space-x-3">
                        {(influencer && influencer.category) && ((influencer.category).split(', ').map((cat, index) => (
                          <span key={index} className="bg-[#f422ff] text-xs text-white rounded-full py-1.5 px-3 mb-3">{cat}</span>
                        )))}
                      </div>
                      {/* <h6 class="username"><a>@{influencer.instagram}</a></h6> */}
                  </div>
                  <div className="social-accounts w-11/12 ml-auto pl-3 mt-5">
                    <table className="w-full">
                      {influencer.tiktok != '' &&
                      <>
                      <tr>
                        <td colSpan={3}>
                          <Link to={`https://www.tiktok.com/@${influencer.tiktok}`} target="_blank" className="btn-social-link">
                            <img src="/images/icons/tiktok.png" className="absolute left-0 h-full"/>
                            <div>{influencer.tiktok}</div>
                            <LuArrowUpRight className="mx-auto" size={13}/>
                          </Link>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <h6>Likes</h6>
                            <h5>{influencer.tiktoklikes}</h5>
                          </div>
                        </td>
                        <td>
                            <div>
                              <h6>Followers</h6>
                              <h5>{influencer.tiktokfollowers}</h5>
                            </div>
                        </td>
                        <td>
                            <div>
                              <h6>Following</h6>
                              <h5>{influencer.tiktokfollowing}</h5>
                            </div>
                        </td>
                      </tr>
                      </>
                      }
                      {influencer.instagram != '' &&
                      <>
                      <tr>
                        <td colSpan={3}>
                          <Link to={`https://www.instagram.com/${influencer.instagram}`} target="_blank" className="mt-5 btn-social-link">
                            <img src="/images/icons/instagram.png" className="absolute left-0 h-full"/>
                            <div>{influencer.instagram}</div>
                            <LuArrowUpRight className="mx-auto" size={13}/>
                          </Link>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                              <h6>Posts</h6>
                              <h5>{influencer.instagramposts}</h5>
                          </div>
                        </td>
                        <td>
                            <div>
                              <h6>Followers</h6>
                              <h5>{influencer.instagramfollowers}</h5>
                            </div>
                        </td>
                        <td>
                            <div>
                              <h6>Following</h6>
                              <h5>{influencer.instagramfollowing}</h5>
                            </div>
                        </td>
                      </tr>
                      </>
                      }
                      {influencer.youtube != '' &&
                      <>
                      <tr>
                        <td colSpan={3}>
                          <Link to={`https://www.youtube.com/@${influencer.youtube}`} target="_blank" className="mt-5 btn-social-link">
                            <img src="/images/icons/youtube.png" className="absolute left-0 h-full"/>
                            <div>{influencer.youtube}</div>
                            <LuArrowUpRight className="mx-auto" size={13}/>
                          </Link>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                              <h6>Videos</h6>
                              <h5>{influencer.youtubevideos}</h5>
                          </div>
                        </td>
                        <td>
                            <div>
                              <h6>Views</h6>
                              <h5>{influencer.youtubeviews}</h5>
                            </div>
                        </td>
                        <td>
                            <div>
                              <h6>Subscribers</h6>
                              <h5>{influencer.youtubesubscribers}</h5>
                            </div>
                        </td>
                      </tr>
                      </>
                      }
                      {influencer.twitch != '' &&
                      <>
                      <tr>
                        <td colSpan={3}>
                          <Link to={`https://www.twitch.tv/${influencer.twitch}`} target="_blank" className="mt-5 btn-social-link">
                            <img src="/images/icons/twitch.png" className="absolute left-0 h-full"/>
                            <div>{influencer.twitch}</div>
                            <LuArrowUpRight className="mx-auto" size={13}/>
                          </Link>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                              <h6>Avg. Viewers</h6>
                              <h5>{influencer.twitchavgviews}</h5>
                          </div>
                        </td>
                        <td>
                            <div>
                              <h6>Followers</h6>
                              <h5>{influencer.twitchfollowers}</h5>
                            </div>
                        </td>
                        <td>
                            <div>
                              <h6>Subscribers</h6>
                              <h5>{influencer.twitchsubscribers}</h5>
                            </div>
                        </td>
                      </tr>
                      </>
                      }
                    </table>
                    {/*                     
                    <div className="flex flex-col space-y-5">
                      {influencer.tiktok != '' &&
                      <div>
                        <button className="p-2 pr-4 pl-3 bg-highlight rounded-full text-neutral-900 text-xs relative font-semibold flex justify-between items-center space-x-8">
                          <img src="/images/icons/tiktok.png" className="absolute left-0 h-full"/>
                          <div>{influencer.tiktok}</div>
                          <LuArrowUpRight className="mx-auto" size={13}/>
                        </button>
                        <div className="flex justify-between gap-x-5 mt-3">
                          <div className="w-1/3">
                            <div>
                              <h6>Likes</h6>
                              <h5>{influencer.tiktoklikes}</h5>
                            </div>
                          </div>
                          <div className="w-1/3 flex justify-center">
                            <div>
                              <h6>Followers</h6>
                              <h5>{influencer.tiktokfollowers}</h5>
                            </div>
                          </div>
                          <div className="w-1/3 flex justify-end">
                            <div>
                              <h6>Following</h6>
                              <h5>{influencer.tiktokfollowing}</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                      }
                      {influencer.instagram != '' &&
                      <div>
                        <button className="p-2 pr-4 pl-3 bg-highlight rounded-full text-neutral-900 text-xs relative font-semibold flex justify-between items-center space-x-8">
                          <img src="/images/icons/instagram.png" className="absolute left-0 h-full"/>
                          <div>{influencer.instagram}</div>
                          <LuArrowUpRight className="mx-auto" size={13}/>
                        </button>
                        <div className="flex justify-between gap-x-5 mt-3">
                          <div className="w-1/3">
                            <div>
                              <h6>Posts</h6>
                              <h5>{influencer.instagramposts}</h5>
                            </div>
                          </div>
                          <div className="w-1/3 flex justify-center">
                            <div>
                              <h6>Followers</h6>
                              <h5>{influencer.instagramfollowers}</h5>
                            </div>
                          </div>
                          <div className="w-1/3 flex justify-end">
                            <div>
                              <h6>Following</h6>
                              <h5>{influencer.instagramfollowing}</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                      }
                      {influencer.youtube != '' &&
                      <div>
                        <button className="p-2 pr-4 pl-3 bg-highlight rounded-full text-neutral-900 text-xs relative font-semibold flex justify-between items-center space-x-8">
                          <img src="/images/icons/youtube.png" className="absolute left-0 h-full"/>
                          <div>{influencer.youtube}</div>
                          <LuArrowUpRight className="mx-auto" size={13}/>
                        </button>
                        <div className="flex justify-between gap-x-5 mt-3">
                          <div className="w-1/3">
                            <div>
                              <h6>Videos</h6>
                              <h5>{influencer.youtubevideos}</h5>
                            </div>
                          </div>
                          <div className="w-1/3 flex justify-center">
                            <div>
                              <h6>Views</h6>
                              <h5>{influencer.youtubeviews}</h5>
                            </div>
                          </div>
                          <div className="w-1/3 flex justify-end">
                            <div>
                              <h6>Subscribers</h6>
                              <h5>{influencer.youtubesubscribers}</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                      }
                      {influencer.twitch != '' &&
                      <div>
                        <button className="p-2 pr-4 pl-3 bg-highlight rounded-full text-neutral-900 text-xs relative font-semibold flex justify-between items-center space-x-8">
                          <img src="/images/icons/twitch.png" className="absolute left-0 h-full"/>
                          <div>{influencer.twitch}</div>
                          <LuArrowUpRight className="mx-auto" size={13}/>
                        </button>
                        <div className="flex justify-between gap-x-5 mt-3">
                          <div className="w-1/3">
                            <div>
                              <h6>Average Viewers</h6>
                              <h5>{influencer.twitchavgviews}</h5>
                            </div>
                          </div>
                          <div className="w-1/3 flex justify-center">
                            <div>
                              <h6>Followers</h6>
                              <h5>{influencer.twitchfollowers}</h5>
                            </div>
                          </div>
                          <div className="w-1/3 flex justify-end">
                            <div>
                              <h6>Subscribers</h6>
                              <h5>{influencer.twitchsubscribers}</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                      }
                    </div> */}
                  </div>
                  {/*                   
                  <div class="boxes grid grid-cols-2">
                    <div>
                      <div>
                        <div>
                            <h5>{influencer.tiktoklikes}</h5>
                            <h6>Likes</h6>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div>
                        <div>
                            <h5>{influencer.tiktokfollowers}</h5>
                            <h6>Followers</h6>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div>
                        <div>
                            <h5>{influencer.tiktokfollowing}</h5>
                            <h6>Following</h6>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div>
                        <div>
                            <h5><LuArrowUpRight className="mx-auto" size={26}/></h5>
                            <h6>Visit full profile</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="flex justify-center">
                      { sharedAlready == 1 || influencer.ifshared == 1 ? 
                      <button class="mx-auto btn-share flex items-center space-x-2">
                          <span>Shared with Team</span> <TbChecks size={16}/>
                      </button>
                      :
                      <button class="mx-auto btn-share flex items-center space-x-3" onClick={shareWithTeam}>
                          <FiUsers size={16}/> <span>Share profile with team</span>
                      </button>
                      }
                  </div>
                   */}
                  </div>
                  {/* <div class="mt-5 other-profiles">
                      <div>Explore their other profiles</div>
                      <div class="mt-3">
                        <div className="flex space-x-3 justify-center">
                          {influencer.tiktok != '' && <img src="/images/icons/tiktok.png"/>}
                          {influencer.instagram != '' && <img src="/images/icons/instagram.png"/>}
                          {influencer.youtube != '' && <img src="/images/icons/youtube.png"/>}
                          {influencer.twitch != '' && <img src="/images/icons/twitch.png"/>}
                        </div>
                      </div>
                  </div> */}
                  </div>
                  </>
                  }
              </div>
            </div>
              }
            </div>
        </div>
        </>
        ) }
      </section>
    </AppLayout>
  );
};

export default AllInfluencers;
