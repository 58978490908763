import AuthLayout from '../layouts/AuthLayout'
import React, { useEffect, useState, useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import { useParams, Link } from 'react-router-dom';
import Bounce from "react-activity/dist/Bounce";
import "react-activity/dist/Bounce.css";
import { MdOutlinePassword } from "react-icons/md"

export default function ResetPassword() {

    const { token } = useParams();
    const [newpassword, setNewPassword] = useState('');
    const [confirmpassword, setConfirmPassword] = useState('');
    const [passwordreset, setPasswordreset] = useState(false);
    const [error, setError] = useState('');
    const [resp, setResp] = useState('');

    const { resetpassword, validatetoken, isLoading } = useContext(AuthContext);

    useEffect(() => {
        validateToken()
    }, [token]);
    
    const validateToken = async () => {
        const response = await validatetoken(token);
        setResp(response)
        // Update the state with the received products
    }

    const handleSubmit = async (e) => {
      e.preventDefault();
      if(isLoading === false){
      try {
        if(newpassword !== "" && confirmpassword !== ""){
            if (newpassword.length < 6 || confirmpassword.length < 6) {
                setError('Password must have at least 6 characters');
                return;
            }
            else if (newpassword !== confirmpassword) {
                setError('Passwords don\'t match');
                return;
            }
            else{
                setError('')
                const response = await resetpassword(token, newpassword, confirmpassword);
                if(response === 'success'){
                    setPasswordreset(true);
                }
            }    
        }
        else{
            setError('All fields are required')
            return;
        }
      } catch (error) {
            setError('failed: '+ error)
      }
    }  
    };

  return (
    <AuthLayout page="Reset Password" heading={passwordreset ? "Password Updated Successfully" : "Reset your password"} subheading={passwordreset ? `<br/>Your password has been reset. You can now log in to your account using your new password.<br/>If you have any further questions or need assistance, please feel free to contact our support team.` : ''}>
        { passwordreset == false ? 
        <form onSubmit={handleSubmit} className="login-form">
        <>
            {resp === 'success' || resp === '' ? <h6 className='text-neutral-400 text-md text-center mb-10'>Password must have at least 6 characters</h6> : '' }
            {resp === 'invalid' ? 
            <>
                <h4 className='text-red-600 text-xl font-semibold text-center mt-10'>Token Expired. Please Try Again!</h4>
            </> : <>
            <div class="space-y-5">
                <div className="form-group password">
                    <input type="password" className='form-control mt-1' id="newpassword" placeholder="New Password" value={newpassword} onChange={(e) => setNewPassword(e.target.value)} required/>
                </div>
                <div className="form-group password">
                    <input type="password" className='form-control mt-1' id="confirmpassword" placeholder="Confirm New Password" value={confirmpassword} onChange={(e) => setConfirmPassword(e.target.value)} required/>
                </div>
            </div>
            {error !== "" ? <div className='text-center text-red-500 font-semibold text-md my-3'>{error}</div> : <div className='py-4'></div>}
            <button type="submit" name="submit" className="w-full btn-main-auth" disabled={isLoading}>
                {isLoading === false ? "Submit" : <Bounce animating={isLoading} />}
            </button>
            </>}
        </>
        </form>
        : <>
        <form>
          <div className="flex flex-row justify-center">
            <Link className="btn btn-main-auth w-64" to="/">
                Sign in
            </Link>
          </div>
        </form>
        </>}
    </AuthLayout>
  )
}
