import AppLayout from "../layouts/AppLayout";
import React, { Fragment, useEffect, useState, useContext } from "react";
import { AppContext } from "../contexts/AppContext";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { RxCross2 } from "react-icons/rx";
import Bounce from "react-activity/dist/Bounce";
import "react-activity/dist/Bounce.css";
import Spinner from "react-activity/dist/Spinner";
import "react-activity/dist/Spinner.css";
import { HiArrowNarrowRight } from "react-icons/hi";
import { FaCheck } from "react-icons/fa6";
import { FaTimes } from "react-icons/fa";

const AddCustomer = () => {
  const { userId } = useParams();

  const [customer, setCustomer] = useState("");
  const [name, setName] = useState("");
  const [lastname, setLastname] = useState("");
  const [company, setCompany] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [status, setStatus] = useState("");
  const [error, setError] = useState("");
  const [modalopen, setModalopen] = useState(false);
  const [reviewaction, setReviewaction] = useState("");

  const { getcustomer, reviewcustomer, updatecustomer, isLoading } = useContext(AppContext);

  useEffect(() => {
    const fetchCustomer = async () => {
      const response = await getcustomer(userId);
      // Update the state with the received products
      if (response) {
        console.log(response);
        setCustomer(response);
        setName(response.name);
        setLastname(response.lastname);
        setCompany(response.company);
        setEmail(response.email);
        setPhone(response.phone);
        setAddress(response.address);
        setStatus(response.status);
      }
    };

    if (userId) {
      fetchCustomer();
    }
  }, [userId]);

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isLoading === false) {
      try {
        if (company !== "" && name !== "" && lastname !== "" && email !== "" && phone !== "" && address !== "") {
          setError("");
          let response = "";
          if (userId) {
            response = await updatecustomer({ company, name, lastname, email, phone, address, userId });
          } else {
            // response = await addcustomer({company, name, lastname, email, phone, address});
          }
          if (response === "success") {
            navigate("/all-customers");
          }
        } else {
          setError("All fields are required");
          return;
        }
      } catch (error) {
        setError("Update failed: " + error);
      }
    }
  };

  const reviewCustomer = async () => {
    const response = await reviewcustomer({ userid: userId, reviewaction });
    if (response === "success") {
      setModalopen(false);
      setReviewaction("");
      navigate("/all-customers");

      //   const response = await getmylistings();
      //   setProducts(response);
    }
  };

  const prepareaction = (action) => {
    setModalopen(true);
    setReviewaction(action);
  };

  const unprepareaction = () => {
    setModalopen(false);
    setReviewaction("");
  };

  return (
    <AppLayout pageName="Update Client">
      <section>
        {status === "Under Review" ? (
          <>
            <div className="flex items-center p-4 mb-5 text-sm text-red-500 rounded-lg bg-red-100" role="alert">
              <svg className="flex-shrink-0 inline w-4 h-4 mr-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
              </svg>
              <span className="sr-only">Info</span>
              <div>
                <span className="font-medium">Under Review!</span> This member needs to be reviewed by the admin.
              </div>
            </div>

            <div className="flex space-x-4 mb-5">
              <button onClick={() => prepareaction("Approved")} type="button" className="btn-submit !bg-green-600  !border-green-600" disabled={isLoading}>
                <span>Approve</span>
                <span>{isLoading === false ? <FaCheck /> : <Bounce animating={isLoading} />}</span>
              </button>
              <button onClick={() => prepareaction("Rejected")} type="button" className="btn-submit !bg-red-500  !border-red-500" disabled={isLoading}>
                <span>Reject</span>
                <span>{isLoading === false ? <FaTimes /> : <Bounce animating={isLoading} />}</span>
              </button>
            </div>

            <Transition.Root show={modalopen} as={Fragment}>
              <Dialog as="div" className="relative z-50" onClose={unprepareaction}>
                <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
                  <div className="fixed inset-0 hidden bg-gray-50 bg-opacity-75 blur-xl transition-opacity md:block" />
                </Transition.Child>

                <div className="fixed inset-0 z-50 overflow-y-auto">
                  <div className="flex min-h-full items-stretch justify-center text-center md:items-center md:px-2 lg:px-4">
                    <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95" enterTo="opacity-100 translate-y-0 md:scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 translate-y-0 md:scale-100" leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95">
                      <Dialog.Panel className="flex w-full transform text-left text-base transition md:my-8 md:max-w-lg md:px-4 lg:max-w-lg">
                        <div className="relative w-full items-center overflow-hidden bg-neutral-800 rounded-lg text-white shadow-2xl p-8">
                          <div className="w-full">
                            <h5 className="text-xl font-medium mb-2">Are you sure you want to {reviewaction} this member?</h5>
                            <h5 className="text-md text-neutral-400 font-light">This action cannot be undone</h5>
                            <button type="button" className="absolute top-3 right-3 text-gray-400 hover:text-gray-500" onClick={() => unprepareaction()}>
                              <span className="sr-only">Close</span>
                              <RxCross2 className="h-5 w-5" aria-hidden="true" />
                            </button>
                          </div>
                          {reviewaction !== "" ? (
                            <div className="px-3 pt-7 mb-3 flex justify-end space-x-2">
                              <button disabled={isLoading} className="btn-main-inverse" onClick={() => unprepareaction()}>
                                No, Cancel
                              </button>
                              <button disabled={isLoading} className="btn-main !px-6" onClick={() => reviewCustomer()}>
                                {isLoading === false ? "Yes, Proceed" : <Spinner animating={isLoading} />}
                              </button>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </Dialog.Panel>
                    </Transition.Child>
                  </div>
                </div>
              </Dialog>
            </Transition.Root>
          </>
        ) : (
          ""
        )}
        <form onSubmit={handleSubmit} method="post" encType="multipart/form-data" className={`flex flex-row flex-wrap w-full ${status === "Under Review" ? "pointer-events-none" : ""}`}>
          <div className="w-2/4 p-2">
            <label htmlFor="company">Company Name</label>
            <input id="company" name="company" required type="text" className="form-control" placeholder="Building Bridges" value={company} onChange={(e) => setCompany(e.target.value)} />
          </div>
          <div className="w-2/4 p-2 flex flex-row items-end space-x-5">
            <div className="w-full">
              <div>
                <label htmlFor="price">Contact Person Name</label>
                <input id="name" name="name" required type="text" className="form-control" placeholder="First Name" value={name} onChange={(e) => setName(e.target.value)} />
              </div>
            </div>
          </div>
          <div className="w-2/4 p-2">
            <label htmlFor="email">Email Address</label>
            <input id="email" name="email" required type="email" className="form-control" placeholder="hello@poppiq.com" value={email} onChange={(e) => setEmail(e.target.value)} maxLength={255} />
          </div>
          <div className="w-2/4 p-2">
            <label htmlFor="phone">Phone Number</label>
            <input id="phone" name="phone" required type="tel" className="form-control" placeholder="(123) 456 7890" value={phone} onChange={(e) => setPhone(e.target.value)} maxLength={20} />
          </div>
          {/* <div className="w-full p-2">
            <label htmlFor="phone">Address</label>
            <input id="address" name="address" required type="text" className="form-control" maxLength={255} placeholder="ABC Street, XYZ Road" value={address} onChange={(e) => setAddress(e.target.value)} />
          </div> */}
          <div className="px-2">
            {error !== "" ? <div className="text-left text-red-500 font-semibold text-md mb-2 tracking-wide">{error}</div> : <div className="py-4"></div>}
            {status === "Under Review" ? (
              ""
            ) : (
              <>
              <div className="flex space-x-3">
                <button type="submit" className="btn-submit" disabled={isLoading}>
                  <span>Save Changes</span>
                  <span className="ml-2">{isLoading === false ? <HiArrowNarrowRight /> : <Bounce animating={isLoading} />}</span>
                </button>
                { userId && 
                  <Link to={'/all-customers'}>
                    <button type="button" className="btn-cancel" disabled={isLoading}>
                      Cancel
                    </button>
                  </Link>
                }
              </div>  
            </>
            )}
          </div>
        </form>
      </section>
    </AppLayout>
  );
};

export default AddCustomer;
