import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext";
import AuthLayout from "../layouts/AuthLayout";
import Bounce from "react-activity/dist/Bounce";
import BASE_URL from "../config";
import "react-activity/dist/Bounce.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import AppleLogin from "react-apple-login";
import axios from "axios";

const clientId = "154085422047-9ajh4gqqvbnqbt6kan8c1moivouv03ik.apps.googleusercontent.com"; // Replace with your Google Client ID

const Home = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const ifredirect = searchParams.get("redirect") != null ? searchParams.get("redirect") : "";

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const { login, isLoading, googleLogin, appleLogin, handleGoogleSuccess, handleAppleSuccess, autherror } = useContext(AuthContext);

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isLoading === false) {
      try {
        if (email !== "" && password !== "") {
          setError("");
          const response = await login(email, password);
          if (response.message === "success") {
            if (ifredirect !== "") {
              navigate(ifredirect);
            } else {
              if(response.userToken[0].type == 'Admin'){
                navigate("/dashboard");
              }
              else{
                if(response.userToken[0].status == 'Email'){
                  navigate("/verify");
                }else{
                navigate("/discover");
                }
              }
            }
          } else {
            setError(response.message);
          }
        } else {
          setError("All fields are required");
          return;
        }
      } catch (error) {
        setError("Login failed: " + error);
      }
    }
  };

  // const handleGoogleLogin = (e) => {
  //   e.preventDefault()
  //   googleLogin()
  // }

  const handleAppleLogin = (e) => {
    e.preventDefault()
    appleLogin()
  }

  const handleGoogleLogin = async (resp) => {
    if (isLoading === false) {
      try {
          setError("");
          const response = await handleGoogleSuccess(resp);
          if (response.message === "success") {
            if (ifredirect !== "") {
              navigate(ifredirect);
            } else {
              if(response.userToken.type == 'Admin'){
                navigate("/dashboard");
              }
              else{
                if(response.userToken.status == 'Email'){
                  navigate("/verify");
                }else{
                navigate("/discover");
                }
              }
            }
          } else {
            setError(response.message);
          }
      } catch (error) {
        setError("Login failed: " + error);
      }
    }
  };

  return (
    <AuthLayout page="Login" heading="Sign in to Poppiq" subheading="We suggest using the <span>email address you use at work.</span>">
      {/* <div>
          <div class="pills">
              <button class="active">Login</button>
              <Link to="/register">Register</Link>
          </div>
      </div> */}
      <form onSubmit={handleSubmit} className="login-form">
        <div class="space-y-5">
          <div>
            {/* <GoogleOAuthProvider clientId={clientId}>
              <GoogleLogin width="100%"
                onSuccess={handleGoogleLogin}
                onError={() => console.log("Google Login Failed")}
              />
            </GoogleOAuthProvider> */}
            <button type="button" className="btn btn-outline-secondary btn-google" onClick={handleGoogleLogin}>
              <img src="/images/icons/google.png" alt="Google Icon" />
              <span>Sign In with Google</span>
            </button>

            {/* <AppleLogin
              clientId="YOUR_APPLE_CLIENT_ID"
              redirectURI="https://yourredirecturl.com"
              usePopup={true}
              onSuccess={handleAppleSuccess}
              onError={(error) => console.error("Apple Login Failed", error)}
            /> */}
            <button type="button" className="btn btn-outline-secondary btn-apple" onClick={handleAppleLogin}>
              <img src="/images/icons/apple.png" alt="Apple Icon" />
              <span>Sign In with Apple</span>
            </button>
          </div>
        </div>
        <div class="or-divider">
            <hr/>
            <span>OR</span>
            <hr/>
        </div>
        <div>
          <div className="space-y-5"> 
            <div className="form-group email">
              <label htmlFor="email">Email address</label>
              <input id="email" name="email" required type="email" className="form-control mt-1" placeholder="name@work-email.com" onChange={(e) => setEmail(e.target.value)} value={email} />
            </div>
            <div className="form-group password">
              <label htmlFor="password">Password</label>
              <input id="password" name="password" required type="password" className="form-control mt-1" placeholder="Your password" onChange={(e) => setPassword(e.target.value)} value={password} />
            </div>
          </div>
          {error !== "" ? <div className="text-center text-red-500 font-semibold text-md my-3">{error}</div> : <div className="py-4"></div>}
          <button type="submit" name="submit" className="w-full btn-main-auth" disabled={isLoading}>
            {isLoading === false ? "Sign In" : <Bounce animating={isLoading} />}
          </button>
          <div className="mt-5 text-neutral-500 text-sm font-medium">
            Forgot your password? <Link className="text-blue-500 font-semibold" to="/forgot-password">Get help signing in</Link>
          </div>
        </div>
      </form>
    </AuthLayout>
  );
};

export default Home;
