import React, { createContext, useState, useEffect, useContext } from "react";
import axios from "axios";
import BASE_URL from "../config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import io from "socket.io-client";
import { AuthContext } from "./AuthContext";
import { AiOutlineConsoleSql } from "react-icons/ai";

const AppContext = createContext();

const AppProvider = ({ children }) => {
  const { userToken, setIsLoggedIn, isLoggedIn, setuserToken } = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState(false);
  const [searchloading, setSearchloading] = useState(false);
  const [sharedwithteamid, setsharedwithteamid] = useState('');
  const [isUnreadLoading, setIsUnreadLoading] = useState(true);
  const [apperror, setApperror] = useState("");
  // const [userToken, setuserToken] = useState('');
  const [totalUnread, setTotalUnread] = useState([]);
  const [socket, setSocket] = useState(null);
  // const sessionToken = JSON.parse(localStorage.getItem("userToken"));

  const verifySession = async () => {
    if (userToken) {
      // Only run if userToken is available
      try {
        const response = await axios.post(`${BASE_URL}/user/verifysession`, {
          userid: userToken.userid,
        });

        if (response.data.message === "success") {
          localStorage.setItem("userToken", JSON.stringify(response.data.userToken[0]));

          const token = JSON.parse(localStorage.getItem("userToken"));
          setuserToken(token);
        } else {
          logout();
        }
      } catch (error) {
        console.error("Verify failed: " + error);
        logout(); // Consider logging out on error as well
      }
    }
  };

  const completeonboarding = async (company, name) => {
    try {
      const userToken = JSON.parse(localStorage.getItem("userToken"));
      setIsLoading(true);
      const response = await axios.post(`${BASE_URL}/user/onboard`, {
        company,
        name,
        userid: userToken.userid,
      });

      if (response.data.message === "success") {
        localStorage.setItem("userToken", JSON.stringify(response.data.userToken[0]));
        // toast.success("Profile Updated Successfully!");
        setIsLoading(false);
        return "success";
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Request failed: " + error);
    }
    setIsLoading(false);
  };

  const fetchUnreadMessages = async () => {
    try {
      const response = await axios.post(`${BASE_URL}/messages/allunread`, {
        userid: userToken.userid,
        usertype: userToken.type,
      });
      if (response.data.message === "success") {
        setTotalUnread(response.data.result);
      }
    } catch (error) {
      console.error("Error fetching unread messages:", error);
    } finally {
      setIsUnreadLoading(false);
    }
  };

  useEffect(() => {
    if(userToken){
        setsharedwithteamid(userToken.teammember == 0 ? (userToken.userid+userToken.userid+userToken.userid) : (userToken.teamid+userToken.teamid+userToken.teamid));
    }

    const interval = setInterval(verifySession, 60000 * 1); // Check every minute
    return () => clearInterval(interval);
  }, [userToken]); // Depend on userToken

  const getdashboard = async () => {
    try {
      setIsLoading(true);
      const userToken = JSON.parse(localStorage.getItem("userToken"));
      const response = await axios.post(`${BASE_URL}/dashboard/stats`, {
        userid: userToken.userid,
        usertype: userToken.type,
      });

      if (response.data.message === "success") {
        return response.data.result;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    } finally {
      setIsLoading(false);
    }
  };

  const getcustomers = async (statusfilter) => {
    try {
      setIsLoading(true);
      const response = await axios.post(`${BASE_URL}/customers/all`, { statusfilter });

      if (response.data.message === "success") {
        setIsLoading(false);
        return response.data.result;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    } finally {
      setIsLoading(false);
    }
  };

  const getcustomer = async (userid) => {
    try {
      setIsLoading(true);
      const response = await axios.post(`${BASE_URL}/customers/get`, {
        userid,
      });

      if (response.data.message === "success") {
        setIsLoading(false);
        return response.data.result;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    } finally {
      setIsLoading(false);
    }
  };

  const reviewcustomer = async ({ userid, reviewaction }) => {
    try {
      setIsLoading(true);
      const response = await axios.post(`${BASE_URL}/customers/review`, {
        userid,
        reviewaction,
      });

      if (response.data.message === "success") {
        setIsLoading(false);
        toast.success("Client Account " + reviewaction + " Successfully!");
        return response.data.message;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    } finally {
      setIsLoading(false);
    }
  };

  const updatecustomer = async ({ company, name, lastname, email, phone, address, userId }) => {
    try {
      setIsLoading(true);
      const response = await axios.post(`${BASE_URL}/customers/update`, {
        company,
        name,
        lastname,
        email,
        phone,
        address,
        userid: userId,
      });

      if (response.data.message === "success") {
        toast.success("Client Updated Successfully!");
        setIsLoading(false);
        return response.data.message;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    } finally {
      setIsLoading(false);
    }
  };

  const deletecustomer = async (userid) => {
    try {
      const userToken = JSON.parse(localStorage.getItem("userToken"));
      setIsLoading(true);
      const response = await axios.post(`${BASE_URL}/customers/delete`, {
        userid,
      });

      if (response.data.message === "success") {
        setIsLoading(false);
        toast.success("Client Deleted Successfully!");
        return "success";
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    }
    setIsLoading(false);
  };
  
  const getinfluencers = async (filters = {}, limit, offset) => {
    try {
      // setIsLoading(true);
      // Ensure `filters` is an object and format it properly
      const formattedFilters = {
        tier: filters.tier?.join(",") || "",
        location: filters.location?.join(",") || "",
        category: filters.category?.join(",") || "",
        gender: filters.gender?.join(",") || "",
        platform: filters.platform?.join(",") || "",
        searchTerm: filters.searchTerm || "",
        limit,
        offset,
      };
  
      const response = await axios.post(`${BASE_URL}/discover/all`, formattedFilters);
  
      if (response.data.message === "success") {
        return response.data.result;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error.message);
    } finally {
      setIsLoading(false);
    }
  };
  
  const getfilteroptions = async () => {
    try {
      setIsLoading(true);
      const response = await axios.post(`${BASE_URL}/discover/options`);
      if (response.data.message === "success") {
        return response.data.result;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    } finally {
      setIsLoading(false);
    }
  };

  const getinfluencer = async ( influencerid ) => {
    try {
      const userToken = JSON.parse(localStorage.getItem("userToken"));
      const response = await axios.post(`${BASE_URL}/influencers/get`, {
        influencerid, listid: sharedwithteamid, userid: userToken.userid
      });

      if (response.data.message === "success") {
        return response.data.result;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    } finally {
    }
  };

  const getlistinfluencers = async (statusfilter, listId, limit, offset) => {
    try {
      if(offset == 0){
      // setIsLoading(true);
      }
      const response = await axios.post(`${BASE_URL}/lists/influencers`, { statusfilter, listId, limit, offset });

      if (response.data.message === "success") {
        return response.data.result;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    } finally {
      setIsLoading(false);
    }
  };

  const saveit = async ( influencerid ) => {
    try {
      const userToken = JSON.parse(localStorage.getItem("userToken"));
      const response = await axios.post(`${BASE_URL}/lists/save`, {
        influencerid, listid: userToken.userid, userid: userToken.userid
      });

      if (response.data.message === "success") {
        toast.success('Saved Successfully!');
        return response.data.message;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    } finally {
    }
  };

  const unsaveit = async ( influencerid ) => {
    try {
      const userToken = JSON.parse(localStorage.getItem("userToken"));
      const response = await axios.post(`${BASE_URL}/lists/unsave`, {
        influencerid, listid: userToken.userid, userid: userToken.userid
      });

      if (response.data.message === "success") {
        return response.data.message;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    } finally {
    }
  };

  const sharewithteam = async ( influencerid ) => {
    try {
      const userToken = JSON.parse(localStorage.getItem("userToken"));
      const response = await axios.post(`${BASE_URL}/lists/sharewithteam`, {
        influencerid, listid: sharedwithteamid, userid: userToken.userid
      });

      if (response.data.message === "success") {
        toast.success('Shared with team Successfully!');
        return response.data.message;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    } finally {
    }
  };

  const unsharewithteam = async ( influencerid ) => {
    try {
      const userToken = JSON.parse(localStorage.getItem("userToken"));
      const response = await axios.post(`${BASE_URL}/lists/unsharewithteam`, {
        influencerid, listid: sharedwithteamid, userid: userToken.userid
      });

      if (response.data.message === "success") {
        toast.success('Unshared with team Successfully!');
        return response.data.message;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    } finally {
    }
  };

  const savetolist = async ( influencerid, listId ) => {
    try {
      const userToken = JSON.parse(localStorage.getItem("userToken"));
      const response = await axios.post(`${BASE_URL}/lists/save`, {
        influencerid, listid: listId, userid: userToken.userid
      });

      if (response.data.message === "success") {
        toast.success('Saved to List');
        return response.data.message;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    } finally {
    }
  };

  const removefromlist = async ( influencerid, listId ) => {
    try {
      const userToken = JSON.parse(localStorage.getItem("userToken"));
      const response = await axios.post(`${BASE_URL}/lists/remove`, {
        influencerid, listid: listId, userid: userToken.userid
      });

      if (response.data.message === "success") {
        toast.success('Removed from List');
        return response.data.message;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    } finally {
    }
  };

  const bulkremovefromlist = async ( influencerid, listId ) => {
    try {
      const userToken = JSON.parse(localStorage.getItem("userToken"));
      const response = await axios.post(`${BASE_URL}/lists/bulkremove`, {
        influencerid, listid: listId, userid: userToken.userid
      });

      if (response.data.message === "success") {
        toast.success('Removed from List');
        return response.data.message;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    } finally {
    }
  };

  const deletemylist = async (listid) => {
    try {
      const userToken = JSON.parse(localStorage.getItem("userToken"));
      // setIsLoading(true);
      const response = await axios.post(`${BASE_URL}/lists/delete`, {
        listid, userid: userToken.userid
      });

      if (response.data.message === "success") {
        toast.success("List Deleted Successfully!");
        return "success";
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    } finally {
      setIsLoading(false);
    }
  };

  const addnewlist = async ( name ) => {
    try {
      const userToken = JSON.parse(localStorage.getItem("userToken"));
      const response = await axios.post(`${BASE_URL}/lists/add`, {
        name, userid: userToken.userid
      });

      if (response.data.message === "success") {
        toast.success('List Added Successfully!');
        return response.data;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    } finally {
    }
  };

  const getlists = async ( influencerid ) => {
    try {
      const userToken = JSON.parse(localStorage.getItem("userToken"));
      const response = await axios.post(`${BASE_URL}/lists/all`, {
        userid: userToken.userid
      });

      if (response.data.message === "success") {
        return response.data.result;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    } finally {
    }
  };

  const addnewcampaign = async (name, startdate, enddate, listid) => {
    try {
      const userToken = JSON.parse(localStorage.getItem("userToken"));
      const response = await axios.post(`${BASE_URL}/campaigns/add`, {
        name, startdate, enddate, listid, userid: userToken.userid
      });

      if (response.data.message === "success") {
        toast.success("Campaign Added Successfully!");
        return response.data;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Catch: " + error.response.data);
    } finally {
      setIsLoading(false);
    }
  };

  const getmycampaigns = async (statusfilter) => {
    try {
      setIsLoading(true);
      const userToken = JSON.parse(localStorage.getItem("userToken"));
      const response = await axios.post(`${BASE_URL}/campaigns/all`, { statusfilter, userid: userToken.userid });
      if (response.data.message === "success") {
        return response.data.result;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    } finally {
      setIsLoading(false);
    }
  };

  const getmycampaign = async (campaignid) => {
    try {
      // setIsLoading(true);
      const userToken = JSON.parse(localStorage.getItem("userToken"));
      const response = await axios.post(`${BASE_URL}/campaigns/get`, {
        campaignid,
        userid: userToken.userid,
      });

      if (response.data.message === "success") {
        return response.data.result;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    } finally {
      setIsLoading(false);
    }
  };

  const updatemycampaign = async (name, startdate, enddate, status, campaignid) => {
    try {
      setIsLoading(true);
      const response = await axios.post(`${BASE_URL}/campaigns/update`, {
        name, startdate, enddate, status, userid: userToken.userid, campaignid
      });

      if (response.data.message === "success") {
        toast.success("Campaign Updated Successfully!");
        return response.data.message;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Catch: " + error.response.data);
    } finally {
      setIsLoading(false);
    }
  };

  const updatemycampaignstatus = async (campaignid, status) => {
    try {
      const response = await axios.post(`${BASE_URL}/campaigns/status/update`, {
        status, userid: userToken.userid, campaignid
      });

      if (response.data.message === "success") {
        toast.success("Campaign Updated Successfully!");
        return response.data.message;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Catch: " + error.response.data);
    } finally {
    }
  };

  const deletemycampaign = async (campaignid) => {
    try {
      const userToken = JSON.parse(localStorage.getItem("userToken"));
      // setIsLoading(true);
      const response = await axios.post(`${BASE_URL}/campaigns/delete`, {
        campaignid, userid: userToken.userid
      });

      if (response.data.message === "success") {
        toast.success("Campaign Deleted Successfully!");
        return "success";
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    } finally {
      setIsLoading(false);
    }
  };

  const getmycampaignlists = async (campaignid) => {
    try {
      setIsLoading(true);
      const userToken = JSON.parse(localStorage.getItem("userToken"));
      const response = await axios.post(`${BASE_URL}/campaigns/lists/all`, {
        campaignid,
        userid: userToken.userid,
      });

      if (response.data.message === "success") {
        return response.data.result;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    } finally {
      setIsLoading(false);
    }
  };

  const getmycampaignlistsinfluencers = async (campaignid) => {
    try {
      setIsLoading(true);
      const userToken = JSON.parse(localStorage.getItem("userToken"));
      const response = await axios.post(`${BASE_URL}/campaigns/lists/influencers/all`, {
        campaignid,
        userid: userToken.userid,
      });

      if (response.data.message === "success") {
        return response.data.result;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    } finally {
      setIsLoading(false);
    }
  };

  const updateinfluencercampaignlistid = async (influencerid, campaignid, oldlistid, newlistid) => {
    try {
      const userToken = JSON.parse(localStorage.getItem("userToken"));
      const response = await axios.post(`${BASE_URL}/campaigns/lists/influencers/update`, {
        influencerid, oldlistid, newlistid, campaignid,
        userid: userToken.userid,
      });

      if (response.data.message === "success") {
        return response.data.message;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    } finally {
    }
  };

  const removefromcampaignlist = async ( influencerid, listid, campaignid ) => {
    try {
      const userToken = JSON.parse(localStorage.getItem("userToken"));
      const response = await axios.post(`${BASE_URL}/campaigns/lists/influencers/delete`, {
        influencerid, listid, campaignid, userid: userToken.userid
      });

      if (response.data.message === "success") {
        toast.success('Removed from List');
        return response.data.message;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    } finally {
    }
  };


  const subscribePlan = async ( planId ) => {
    try {
      const userToken = JSON.parse(localStorage.getItem("userToken"));
      const response = await axios.post(`${BASE_URL}/create-checkout-session`, {
        userId: userToken.userid,
        planId
      });

      toast.success(response.data.message);
      return response.data;
      if (response.data.message === "success") {
        // toast.success('Plans Subscribed Successfully');
        return response.data;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    } finally {
    }
  };

  const addinfluencer = async ({ name, category, location, instagramUsername, instagramPosts, instagramFollowers, instagramFollowing, tiktokUsername, tiktokLikes, tiktokFollowers, tiktokFollowing, youtubeUsername, youtubeVideos, youtubeViews, youtubeSubscribers, twitchUsername, twitchFollowers, twitchAvgViews, twitchSubscribers, image }) => {
    try {
      setIsLoading(true);

      const formData = new FormData(); // Use FormData to handle image uploads
      formData.append("name", name);
      formData.append("category", category);
      formData.append("location", location);
      formData.append("instagramUsername", instagramUsername);
      formData.append("instagramPosts", instagramPosts);
      formData.append("instagramFollowers", instagramFollowers);
      formData.append("instagramFollowing", instagramFollowing);
      formData.append("tiktokUsername", tiktokUsername);
      formData.append("tiktokLikes", tiktokLikes);
      formData.append("tiktokFollowers", tiktokFollowers);
      formData.append("tiktokFollowing", tiktokFollowing);
      formData.append("youtubeUsername", youtubeUsername);
      formData.append("youtubeVideos", youtubeVideos);
      formData.append("youtubeViews", youtubeViews);
      formData.append("youtubeSubscribers", youtubeSubscribers);
      formData.append("twitchUsername", twitchUsername);
      formData.append("twitchFollowers", twitchFollowers);
      formData.append("twitchAvgViews", twitchAvgViews);
      formData.append("twitchSubscribers", twitchSubscribers);

      // Append the image file if it exists
      if (image) {
        formData.append("image", image);
      }


      const response = await axios.post(`${BASE_URL}/influencers/add`, formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      });

      if (response.data.message === "success") {
        toast.success("Influencer Added Successfully!");
        return response.data;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    } finally {
      setIsLoading(false);
    }
  };

  const updateinfluencer = async ({ name, category, location, instagramUsername, instagramPosts, instagramFollowers, instagramFollowing, tiktokUsername, tiktokLikes, tiktokFollowers, tiktokFollowing, youtubeUsername, youtubeVideos, youtubeViews, youtubeSubscribers, twitchUsername, twitchFollowers, twitchAvgViews, twitchSubscribers, image, influencerId }) => {
    try {
      setIsLoading(true);

      const formData = new FormData(); // Use FormData to handle image uploads
      formData.append("name", name);
      formData.append("category", category);
      formData.append("location", location);
      formData.append("instagramUsername", instagramUsername);
      formData.append("instagramPosts", instagramPosts);
      formData.append("instagramFollowers", instagramFollowers);
      formData.append("instagramFollowing", instagramFollowing);
      formData.append("tiktokUsername", tiktokUsername);
      formData.append("tiktokLikes", tiktokLikes);
      formData.append("tiktokFollowers", tiktokFollowers);
      formData.append("tiktokFollowing", tiktokFollowing);
      formData.append("youtubeUsername", youtubeUsername);
      formData.append("youtubeVideos", youtubeVideos);
      formData.append("youtubeViews", youtubeViews);
      formData.append("youtubeSubscribers", youtubeSubscribers);
      formData.append("twitchUsername", twitchUsername);
      formData.append("twitchFollowers", twitchFollowers);
      formData.append("twitchAvgViews", twitchAvgViews);
      formData.append("twitchSubscribers", twitchSubscribers);
      formData.append("influencerid", influencerId);
  
      // Append the image file if it exists
      if (image) {
        formData.append("image", image);
      }

      const response = await axios.post(`${BASE_URL}/influencers/update`, formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      });

      if (response.data.message === "success") {
        toast.success("Influencer Updated Successfully!");
        console.log(response.data);
        return response.data.message;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    } finally {
      setIsLoading(false);
    }
  };





















  const reviewinfluencer = async ({ userid, reviewaction }) => {
    try {
      setIsLoading(true);
      const response = await axios.post(`${BASE_URL}/influencers/review`, {
        userid,
        reviewaction,
      });

      if (response.data.message === "success") {
        setIsLoading(false);
        toast.success("Influencer Account " + reviewaction + " Successfully!");
        return response.data.message;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    } finally {
      setIsLoading(false);
    }
  };

  const deleteinfluencer = async (userid) => {
    try {
      const userToken = JSON.parse(localStorage.getItem("userToken"));
      setIsLoading(true);
      const response = await axios.post(`${BASE_URL}/influencers/delete`, {
        userid,
      });

      if (response.data.message === "success") {
        setIsLoading(false);
        toast.success("Influencer Deleted Successfully!");
        return response.data.message;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    }
    setIsLoading(false);
  };

  const getcampaigns = async (statusfilter) => {
    try {
      setIsLoading(true);
      const userToken = JSON.parse(localStorage.getItem("userToken"));
      const response = await axios.post(`${BASE_URL}/campaigns/all`, { statusfilter, userid: userToken.userid });

      if (response.data.message === "success") {
        return response.data.result;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    } finally {
      setIsLoading(false);
    }
  };

  const getcampaign = async ({ campaignid }) => {
    try {
      setIsLoading(true);
      const response = await axios.post(`${BASE_URL}/campaigns/get`, {
        campaignid,
      });

      if (response.data.message === "success") {
        setIsLoading(false);
        return response.data.result;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    } finally {
      setIsLoading(false);
    }
  };

  const reviewcampaign = async ({ campaignid, reviewaction, reviewnote }) => {
    try {
      setIsLoading(true);
      const response = await axios.post(`${BASE_URL}/campaigns/review`, {
        campaignid,
        reviewaction,
        reviewnote,
      });

      if (response.data.message === "success") {
        setIsLoading(false);
        toast.success("Campaign " + reviewaction + " Successfully!");
        return response.data.message;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    } finally {
      setIsLoading(false);
    }
  };

  const updatecampaign = async (formData) => {
    try {
      setIsLoading(true);
      const response = await axios.post(`${BASE_URL}/campaigns/update`, formData, { headers: { "Content-Type": "multipart/form-data" } });

      if (response.data.message === "success") {
        toast.success("Campaign Updated Successfully!");
        setIsLoading(false);
        return response.data.message;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    } finally {
      setIsLoading(false);
    }
  };

  const deletecampaign = async (campaignid) => {
    try {
      const userToken = JSON.parse(localStorage.getItem("userToken"));
      setIsLoading(true);
      const response = await axios.post(`${BASE_URL}/campaigns/delete`, {
        campaignid,
      });

      if (response.data.message === "success") {
        setIsLoading(false);
        toast.success("Campaign Deleted Successfully!");
        return "success";
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    }
    setIsLoading(false);
  };

  const getmycampaignvideos = async (campaignid) => {
    try {
      setIsLoading(true);
      const response = await axios.post(`${BASE_URL}/campaigns/getmyvideos`, {
        campaignid,
        userid: userToken.userid,
      });

      if (response.data.message === "success") {
        return response.data.result;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    } finally {
      setIsLoading(false);
    }
  };

  const assigninfluencer = async ({ newinfluencer, newreach, newbudget, newdeadline, newsong, newdescription, campaignId }) => {
    try {
      setIsLoading(true);
      const response = await axios.post(`${BASE_URL}/campaigns/assigninfluencer`, { influencer: newinfluencer, reach: newreach, budget: newbudget, deadline: newdeadline, song: newsong, description: newdescription, campaign: campaignId });

      if (response.data.message === "success") {
        toast.success("Influencer Assigned Successfully!");
        return response.data.message;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Catch: " + error.response.data);
    } finally {
      setIsLoading(false);
    }
  };

  const unassigninfluencer = async (influencer, campaign) => {
    try {
      setIsLoading(true);
      const response = await axios.post(`${BASE_URL}/campaigns/unassigninfluencer`, { campaign, influencer });

      if (response.data.message === "success") {
        toast.success("Influencer Unassigned Successfully!");
        return response.data.message;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Catch: " + error.response.data);
    } finally {
      setIsLoading(false);
    }
  };

  const getcampaigninfluencers = async (campaign) => {
    try {
      setIsLoading(true);
      const userToken = JSON.parse(localStorage.getItem("userToken"));
      const response = await axios.post(`${BASE_URL}/campaigns/assignedinfluencers`, { campaign });
      if (response.data.message === "success") {
        return response.data.result;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    } finally {
      setIsLoading(false);
    }
  };

  const sendinvitation = async (email) => {
    try {
      const response = await axios.post(`${BASE_URL}/team/invite`, {
        email,
        userid: userToken.userid,
      });

      if (response.data.message === "success") {
        toast.success("Invitation Sent Successfully!");
        return response.data.message;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    }
  };

  const resendinvitation = async (email) => {
    try {
      const response = await axios.post(`${BASE_URL}/team/reinvite`, {
        email,
        userid: userToken.userid,
      });

      if (response.data.message === "success") {
        toast.success("Invitation Sent Successfully!");
        return response.data.message;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    }
  };

  const getmyteam = async () => {
    try {
      setIsLoading(true);
      const userToken = JSON.parse(localStorage.getItem("userToken"));
      const response = await axios.post(`${BASE_URL}/team/all`, { userid: userToken.userid });
      if (response.data.message === "success") {
        return response.data.result;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    } finally {
      setIsLoading(false);
    }
  };

  const assigninf = async ( inf, desc, campaignId ) => {
    try {
      setIsLoading(true);
      const response = await axios.post(`${BASE_URL}/campaigns/assigninf`, { influencer: inf, description: desc, campaign: campaignId });

      if (response.data.message === "success") {
        toast.success("Influencer Added Successfully!");
        return response.data.message;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Catch: " + error.response.data);
    } finally {
      setIsLoading(false);
    }
  };

  const unassigninf = async (influencer, campaign) => {
    try {
      setIsLoading(true);
      const response = await axios.post(`${BASE_URL}/campaigns/unassigninf`, { campaign, influencer });

      if (response.data.message === "success") {
        toast.success("Influencer Removed Successfully!");
        return response.data.message;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Catch: " + error.response.data);
    } finally {
      setIsLoading(false);
    }
  };

  const getcampaigninf = async (campaign) => {
    try {
      setIsLoading(true);
      const userToken = JSON.parse(localStorage.getItem("userToken"));
      const response = await axios.post(`${BASE_URL}/campaigns/assignedinf`, { campaign });
      if (response.data.message === "success") {
        return response.data.result;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    } finally {
      setIsLoading(false);
    }
  };

  const getmyassignedcampaigns = async (statusfilter) => {
    try {
      setIsLoading(true);
      const userToken = JSON.parse(localStorage.getItem("userToken"));
      const response = await axios.post(`${BASE_URL}/campaigns/myassigned`, { statusfilter, userid: userToken.userid });
      if (response.data.message === "success") {
        return response.data.result;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    } finally {
      setIsLoading(false);
    }
  };

  const getmyassignedcampaign = async (campaignid) => {
    try {
      setIsLoading(true);
      const userToken = JSON.parse(localStorage.getItem("userToken"));
      const response = await axios.post(`${BASE_URL}/campaigns/getmyassigned`, {
        campaignid,
        userid: userToken.userid,
      });

      if (response.data.message === "success") {
        toast.error(response.data.result);
        return response.data.result;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    } finally {
      setIsLoading(false);
    }
  };

  const verifystripe = async () => {
    const userToken = JSON.parse(localStorage.getItem("userToken"));
    try {
      const response = await axios.post(`${BASE_URL}/stripe/verify`, {
        stripeid: userToken.stripeid,
      });

      if (response.data.message === "success") {
        return response.data;
      }
    } catch (error) {
      toast.error("Failed: " + error);
    }
  };

  const connectstripe = async () => {
    const userToken = JSON.parse(localStorage.getItem("userToken"));
    try {
      const response = await axios.post(`${BASE_URL}/stripe/connect`, {
        user: userToken.userid,
        stripeid: userToken.stripeid,
      });

      if (response.data.message === "success") {
        return response.data;
      }
    } catch (error) {
      toast.error("Failed: " + error);
    }
  };

  const updateprofile = async (company, name, lastname, email, phone) => {
    try {
      const userToken = JSON.parse(localStorage.getItem("userToken"));
      setIsLoading(true);
      const response = await axios.post(`${BASE_URL}/user/update`, {
        company,
        name,
        lastname,
        email,
        phone,
        userid: userToken.userid,
      });

      if (response.data.message === "success") {
        localStorage.setItem("userToken", JSON.stringify(response.data.userToken[0]));
        toast.success("Profile Updated Successfully!");
        setIsLoading(false);
        return "success";
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Request failed: " + error);
    }
    setIsLoading(false);
  };

  const updateprofiledetail = async (valtype, val) => {
    try {
      const userToken = JSON.parse(localStorage.getItem("userToken"));
      setIsLoading(true);
      const response = await axios.post(`${BASE_URL}/user/updatedetail`, {
        valtype, val, userid: userToken.userid,
      });

      if (response.data.message === "success") {
        const updatedUserToken = { ...userToken, [valtype]: val };
        localStorage.setItem("userToken", JSON.stringify(updatedUserToken));
        toast.success("Profile Updated Successfully!");
        return "success";
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Request failed: " + error);
    } finally {
      setIsLoading(false);
    }
  };

  const updateprofilepicture = async (image) => {
    try {
      const userToken = JSON.parse(localStorage.getItem("userToken"));
      setIsLoading(true);
      const response = await axios.post(`${BASE_URL}/user/updateprofilepicture`, {
        image,
        userid: userToken.userid,
      });

      if (response.data.message === "success") {
        const updatedUserToken = { ...userToken, 'image': response.data.image };
        localStorage.setItem("userToken", JSON.stringify(updatedUserToken));
        toast.success("Image Updated Successfully!");
        setIsLoading(false);
        return "success";
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Request failed: " + error);
    }
    setIsLoading(false);
  };

  const updatepassword = async (oldpassword, newpassword, confirmpassword) => {
    try {
      if (newpassword === confirmpassword) {
        const userToken = JSON.parse(localStorage.getItem("userToken"));
        setIsLoading(true);
        const response = await axios.post(`${BASE_URL}/user/updatepassword`, {
          oldpassword,
          newpassword,
          confirmpassword,
          userid: userToken.userid,
        });

        if (response.data.message === "success") {
          toast.success("Password Updated Successfully!");
          setIsLoading(false);
          return "success";
        } else {
          toast.error(response.data.message);
        }
      } else {
        toast.error("Passwords don't match");
      }
    } catch (error) {
      toast.error("Request failed: " + error);
    }
    setIsLoading(false);
  };

  const logout = () => {
    setIsLoggedIn(false);
    localStorage.clear();
    if (socket) {
      socket.disconnect();
    }
    return "success";
  };

  return (
    <AppContext.Provider
      value={{
        userToken,
        socket,
        isLoading,
        verifySession,
        logout,
        getdashboard,
        fetchUnreadMessages,
        completeonboarding,
        getcustomers,
        getcustomer,
        reviewcustomer,
        updatecustomer,
        deletecustomer,
        getinfluencers,
        getfilteroptions,
        getinfluencer,
        getlistinfluencers,
        sharewithteam,
        unsharewithteam,
        saveit,
        unsaveit,
        savetolist,
        bulkremovefromlist,
        removefromlist,
        deletemylist,
        addnewlist,
        getlists,
        reviewinfluencer,
        updateinfluencer,
        addinfluencer,
        deleteinfluencer,
        addnewcampaign,
        updatemycampaign,
        updatemycampaignstatus,
        deletemycampaign,
        getmycampaigns,
        getmycampaignlists,
        getmycampaignlistsinfluencers,
        updateinfluencercampaignlistid,
        removefromcampaignlist,
        subscribePlan,
        getcampaigns,
        getcampaign,
        getmycampaign,
        getmycampaignvideos,
        reviewcampaign,
        updatecampaign,
        deletecampaign,
        getmyteam,
        assigninfluencer,
        unassigninfluencer,
        assigninf,
        unassigninf,
        getcampaigninf,
        getcampaigninfluencers,
        sendinvitation,
        resendinvitation,
        getmyassignedcampaigns,
        getmyassignedcampaign,
        connectstripe,
        verifystripe,
        connectstripe,
        updateprofile,
        updateprofiledetail,
        updateprofilepicture,
        updatepassword,
        searchloading,
        isUnreadLoading,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export { AppContext, AppProvider };
