import React, { useContext, useState, useEffect, useCallback } from "react";
import { debounce } from "lodash";
import Bounce from "react-activity/dist/Bounce";
import "react-activity/dist/Bounce.css";
import { Link } from "react-router-dom";
import AppLayout from "../layouts/AppLayout";
import { AppContext } from "../contexts/AppContext";
import Spinner from "react-activity/dist/Spinner";
import "react-activity/dist/Spinner.css";
import { PiSlidersHorizontal } from "react-icons/pi";
import MultiSelect from "../components/MultiSelect"; // Ensure to create this file for custom styles
import "../styles/discover.scss";
import "../styles/lists.scss";
import { PiUsers } from "react-icons/pi";
import { FiUsers } from "react-icons/fi";
import { LuArrowUpRight } from "react-icons/lu";
import { HiMiniChevronDown, HiMiniChevronUp } from "react-icons/hi2";
import { TbChecks } from "react-icons/tb";
import Modal from "../components/Modal"; // Import the existing modal component
import { LuPlus } from "react-icons/lu";
import { TbX } from "react-icons/tb";
import { FiSearch } from "react-icons/fi";
import { FaRegHeart, FaHeart } from "react-icons/fa";
import { RxPlusCircled } from "react-icons/rx";
import { LuPlusCircle } from "react-icons/lu";
import { PiPlusCircle } from "react-icons/pi";
import { RiEditCircleLine } from "react-icons/ri";
import { GoCheckCircle } from "react-icons/go";
import { TfiTrash } from "react-icons/tfi";
import Swal from 'sweetalert2';
import { useNavigate } from "react-router-dom";

const options = {
  tier: [
    { value: 'Tier 1', label: 'Tier 1' },
    { value: 'Tier 2', label: 'Tier 2' },
    { value: 'Tier 3', label: 'Tier 3' },
  ],
  location: [
    { value: 'Sweden', label: 'Sweden' },
    { value: 'USA', label: 'USA' },
    { value: 'Canada', label: 'Canada' },
  ],
  category: [
    { value: 'Fashion', label: 'Fashion' },
    { value: 'Technology', label: 'Technology' },
    { value: 'Food', label: 'Food' },
  ],
  gender: [
    { value: 'Male', label: 'Male' },
    { value: 'Female', label: 'Female' },
  ],
  platform: [
    { value: 'TikTok', label: 'TikTok' },
    { value: 'Instagram', label: 'Instagram' },
    { value: 'YouTube', label: 'YouTube' },
    { value: 'Twitch', label: 'Twitch' },
  ],
};

const Lists = () => {
  const navigate = useNavigate();

  const { addnewlist, getlists, getlistinfluencers, getinfluencer, sharewithteam, unsharewithteam, saveit, unsaveit, savetolist, removefromlist, bulkremovefromlist, deletemylist, userToken, isLoading } = useContext(AppContext);

  const [influencers, setInfluencers] = useState([]);
  const [lists, setLists] = useState([]);
  const [dropdownlists, setdropdownLists] = useState([]);
  const [influencer, setInfluencer] = useState("");
  const [listId, setListId] = useState("");
  const [selectedListName, setSelectedListName] = useState("");
  const [infId, setInfId] = useState("");
  const [infIds, setInfIds] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [infLoading, setinfLoading] = useState(false);
  const [offset, setOffset] = useState(0);
  const [sharedAlready, setsharedAlready] = useState(0);
  const [savedAlready, setsavedAlready] = useState(0);
  const [loadingMore, setLoadingMore] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [showNewModal, setshowNewModal] = useState(false);
  const [listName, setListName] = useState('');
  const [isFormChanged, setIsFormChanged] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [listsLoading, setListsLoading] = useState(false);
  const [fetchingLoading, setfetchingLoading] = useState(true);
  const [editing, setEditing] = useState(false);
  const [showListsDropdown, setShowListsDropdown] = useState(false);

  const sharedwithteamid = (userToken.teammember == 0 ? (userToken.userid+userToken.userid+userToken.userid) : userToken.teamid+userToken.teamid+userToken.teamid);

  const limit = 10;

  const genericSearch = (items, searchTerm) => {
    if (!searchTerm) {
      return items;
    }

    const lowerCaseSearchTerm = searchTerm.toLowerCase();

    return items.filter((item) =>
      Object.values(item).some((value) =>
        value && value.toString().toLowerCase().includes(lowerCaseSearchTerm)
      )
    );
  };

  const fetchInfluencers = useCallback(async (listId) => {
    if (listId != '') {
      if (!hasMore) return;
      setLoadingMore(true);
      const response = await getlistinfluencers(searchTerm, listId, limit, offset);
      if (response.length > 0) {
        if(influencers.length == 0){
          setInfluencers(response);
        }
        else{
          setInfluencers((prev) => [...prev, ...response]);
        }
        setOffset((prev) => prev + limit);
      } else {
        setHasMore(false);
      }
      setfetchingLoading(false);
      setLoadingMore(false);
    }
  }, [getlistinfluencers, searchTerm, offset, hasMore]);

  const debouncedFetchInfluencers = useCallback(debounce(fetchInfluencers, 300), [searchTerm]);

  useEffect(() => {
    fetchInfluencers(listId); // Pass the current listId here
  }, [listId, debouncedFetchInfluencers]);

  const handleSearch = (searchValue) => {
    setSearchTerm(searchValue);
    setInfluencers([]);
    setOffset(0);
    setHasMore(true);
    fetchInfluencers(listId); // Pass the current listId here
  };

  const handleListChange = (newListId, name) => {
    setListId(newListId);
    setSelectedListName(name);
    setInfluencers([]);
    setOffset(0);
    setEditing(false);
    setHasMore(true);
    fetchInfluencers(newListId);
    setInfId('');
    setShowListsDropdown(false);
  };

  useEffect(() => {
    const fetchInfluencer = async () => {
      if(infId != ''){
        setinfLoading(true);
        setsharedAlready(0);
        const response = await getinfluencer(infId);
        setInfluencer(response[0]);
        setsharedAlready(response[0].ifshared);
        setinfLoading(false);
      }
    };

    fetchInfluencer();
  }, [infId]);

  const handleRemove = (value) => {
    setSelectedOptions(selectedOptions.filter(option => option !== value));
  };

  const handleScroll = (e) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom && !loadingMore) {
      fetchInfluencers(listId);
    }
  };

  const shareWithTeam = async () => {
    if(infId != ''){
      const response = await sharewithteam(infId);
      if(response == 'success'){
        setsharedAlready(1);
      }
    }
  }

  const saveIt = async () => {
    if(infId != ''){
      const response = await saveit(infId);
      if(response == 'success'){
        setsavedAlready(1);
      }
    }
  }

  const unsaveIt = async () => {
    if(infId != ''){
      const response = await unsaveit(infId);
      if(response == 'success'){
        setsavedAlready(0);
        if(listId == userToken.userid){
          setInfluencers(influencers.filter(influencer => influencer.influencerid !== infId));
          setInfId('');
        }
      }
    }
  }

  const saveToList = async (listId) => {
    if(infId != ''){
      const response = await savetolist(infId, listId);
      setdropdownLists(dropdownlists.filter(list => list.listid !== listId));
      if(response == 'success'){
        setShowListsDropdown(false);
      }
    }
  }

  const unshareWithTeam = async () => {
    if(infId != ''){
      const response = await unsharewithteam(infId);
      if(response == 'success'){
        setsharedAlready(0);
        
        setInfluencers((prevInfluencers) =>
          prevInfluencers.map((influencer) =>
            influencer.id === infId ? { ...influencer, ifshared: 0 } : influencer
          )
        );
      }
    }
  }

  const removeFromList = async () => {
    if(infId != ''){
      const response = await removefromlist(infId, listId);
      if(response == 'success'){
        setInfluencers(influencers.filter(influencer => influencer.influencerid !== infId));
        setInfId('');
      }
    }
  }
  
  const handleCheckboxChange = (influencerId) => {
    setInfIds((prev) =>
      prev.includes(influencerId)
        ? prev.filter((id) => id !== influencerId)
        : [...prev, influencerId]
    );
  };
  
  const handleSelectAll = () => {
    if (infIds.length === influencers.length) {
      setInfIds([]); // Deselect all
    } else {
      setInfIds(influencers.map((inf) => inf.influencerid)); // Select all
    }
  };

  useEffect(() => {
    if (listName !== "") {
      setIsFormChanged(true);
    } else {
      setIsFormChanged(false);
    }
  }, [listName]);

  const fetchLists = async () => {
    const response = await getlists();
    console.log(response);
    setLists(response);
    setdropdownLists(response);
  };

  useEffect(() => {
    fetchLists();
  }, []);

  const handleAddNewList = async (e) => {
    setFormLoading(true);
    e.preventDefault();
    const response = await addnewlist(listName);
    if(response.message == 'success'){
      setshowNewModal(false);
      setListName('');
      setFormLoading(false);

      const newList = {
          id: response.id,
          listid: response.listid,
          customer: userToken.userid,
          addedby: userToken.userid,
          name: listName,
      };

      setLists((prevLists) => [...prevLists, newList]);
      setdropdownLists((prevDropdownLists) => [...prevDropdownLists, newList]);

      if(infId != ""){
        const response2 = await savetolist(infId, response.listid);
        if(response2 == 'success'){
          setShowListsDropdown(false);
        }
      }
    }
  };

  const handleBulkDelete = async () => {
    if (!isLoading) {
      try {
        const result = await Swal.fire({
          title: 'Are you sure to remove?',
          text: "You won't be able to undo this action",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, Remove'
        });
  
        if (result.isConfirmed) {
          const response = await bulkremovefromlist(infIds, listId);
          if (response === 'success') {
            setInfluencers(influencers.filter(influencer => !infIds.includes(influencer.influencerid)));
            setInfIds([]);
          }
        }
      } catch (error) {
        Swal.fire("An Error Occurred", error, "error");
      }
    }
  };

  const handleDelete = async (todelete) => {
    if (!isLoading) {
      try {
        const result = await Swal.fire({
          title: 'Are you sure to remove?',
          text: "You won't be able to undo this action",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, Remove'
        });
  
        if (result.isConfirmed) {
          const response = await removefromlist(todelete, listId);
          if (response === 'success') {
            setInfluencers(influencers.filter(influencer => influencer.influencerid !== todelete));
          }
        }
      } catch (error) {
        Swal.fire("An Error Occurred", error, "error");
      }
    }
  };

  const handleDeleteList = async () => {
    if (!isLoading) {
      try {
        const result = await Swal.fire({
          title: 'Are you sure to delete the entire list?',
          text: "You won't be able to undo this action",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, Delete'
        });
  
        if (result.isConfirmed) {
          const response = await deletemylist(listId);
          if (response === 'success') {
            setListId('');
            setEditing(false);
            setLists(lists.filter(templist => templist.listid !== listId));
            setdropdownLists(lists);
          }
        }
      } catch (error) {
        Swal.fire("An Error Occurred", error, "error");
      }
    }
  };

  const handleCreateCampaignClick = () => {
    navigate("/campaigns", { state: { listId } });
  };
  

  return (
    <AppLayout pageId="lists" pageName={"Lists"}>
      <section>
        {isLoading ? (
          <div className="flex justify-center w-full py-12">
            <Spinner color="#CCCCCC" size={24} />
          </div>
        ) : (
          <div className="space-y-5">
            <div className="flex space-x-8">
              <div className={`transition-all ${infId !== '' ? 'w-4/6' : 'w-full'}`}>
                <div className="section-left flex flex-col">
                  <div className="flex justify-between">
                    <div className="page-head">
                      <h4>Saved lists</h4>
                      {lists.length + 2} lists
                    </div>
                    <div>
                      { showNewModal && 
                      <Modal heading="New List" isOpen={showNewModal} onClose={() => setshowNewModal(false)}>
                        <form onSubmit={handleAddNewList}>
                          <div className="modal-body">
                            <div className="mb-8">
                              <label>List Name</label>
                              <input autoFocus className="form-control" type="text" maxLength={30} value={listName} onChange={(e) => setListName(e.target.value)} placeholder="" required/>
                            </div>
                          </div>
                          <div className="modal-footer">
                            {/* <button type="submit" className="btn-submit">Submit</button> */}
                            <button type="submit" className="btn-submit" disabled={formLoading || !isFormChanged}>
                              {formLoading === false ? "Submit" : <Bounce className="my-2" animating={formLoading} />}
                            </button>
                          </div>
                        </form>
                      </Modal>
                      }
                      <button className={`btn-new flex items-center`} onClick={() => setshowNewModal(true)}><PiPlusCircle size={26} className="mr-1.5"/> Create new list</button>
                    </div>
                  </div>
                  <div className="flex flex-wrap lists-wrapper">
                    <div className={`btn-list-item-wrapper ${listId === userToken.userid && 'active'}`} onClick={() => { if(listId !== userToken.userid){ handleListChange(userToken.userid, 'Saved') } }}><button className={`btn-list-item ${listId === userToken.userid && 'active'}`}>Saved</button></div>
                    <div className={`btn-list-item-wrapper ${listId === sharedwithteamid && 'active'}`} onClick={() => { if(listId !== sharedwithteamid){ handleListChange(sharedwithteamid, 'Shared with Team') } }}><button className={`btn-list-item ${listId === userToken.teamid && 'active'}`}>Shared with Team</button></div>
                    { lists.map((list, index) => {
                      return(
                        <div key={index} className={`btn-list-item-wrapper ${listId === list.listid && 'active'}`} onClick={() => { if(listId !== list.listid){ handleListChange(list.listid, list.name) } }}><button className={`btn-list-item ${listId === list.listid && 'active'}`}>{list.name}</button></div>
                      )
                    }) }
                  </div>
                  {listId !== '' &&
                    <>
                    <div className="flex justify-between mt-3 mb-3">
                      <div className="flex space-x-5">
                        <div>
                          <h4 className="text-2xl font-semibold">{selectedListName}</h4>
                          {influencers.length} influencers
                        </div>
                        { (editing && (listId != userToken.userid) && (listId != (userToken.userid+userToken.userid+userToken.userid))) && 
                        <div className="pt-2"><button className="btn-delete-alt flex items-center !px-5" onClick={(e) => handleDeleteList(e)}>Delete List</button></div> 
                        }
                      </div>
                      <div className="flex space-x-5 list-options">
                          { editing ? 
                          <>
                          {infIds.length > 0 && 
                            <div><button className="btn-main-alt flex items-center !px-5" onClick={() => handleBulkDelete()}>Delete Selected</button></div>
                          }
                            <div><button className="btn-main flex items-center !px-8" onClick={() => setEditing(false)}>Done</button></div>
                          </>
                          : 
                          <>
                            <div><button className="btn-main-alt flex items-center" onClick={() => setEditing(true)}><RiEditCircleLine size={20} className="mr-1.5"/> Edit list</button></div>
                            <div><button className="btn-main-alt flex items-center" onClick={() => handleCreateCampaignClick()}><PiPlusCircle size={20} className="mr-1.5"/>Create campaign from this list</button></div>
                          </>
                          }
                      </div>
                    </div>
                      <div className="inf-filters">
                        {/* 
                        <div className="search">
                          <img src="/images/icons/svgs/search-normal.svg" width="15" alt="Search"/>
                          <input
                            className="form-control"
                            placeholder="Search..."
                            value={searchTerm}
                            onChange={(e) => handleSearch(e.target.value)}
                          />
                        </div>
                        <div className="filters my-4">
                          <div className="flex flex-wrap justify-start items-center filters-select space-x-10">
                            <div>
                              <PiSlidersHorizontal size={24} color={'#999'}/>
                            </div>
                            <MultiSelect
                              options={options.tier}
                              selectedOptions={selectedOptions}
                              setSelectedOptions={setSelectedOptions}
                              placeholder="Tier"
                            />
                            <MultiSelect
                              options={options.location}
                              selectedOptions={selectedOptions}
                              setSelectedOptions={setSelectedOptions}
                              placeholder="Location"
                            />
                            <MultiSelect
                              options={options.category}
                              selectedOptions={selectedOptions}
                              setSelectedOptions={setSelectedOptions}
                              placeholder="Category"
                            />
                            <MultiSelect
                              options={options.gender}
                              selectedOptions={selectedOptions}
                              setSelectedOptions={setSelectedOptions}
                              placeholder="Gender"
                            />
                            <MultiSelect
                              options={options.platform}
                              selectedOptions={selectedOptions}
                              setSelectedOptions={setSelectedOptions}
                              placeholder="Platform"
                            />
                          </div>
                        </div>
                        <div className="selected-tags">
                          {selectedOptions.map(option => (
                            <div key={option} className="tag">
                              {option}
                              <span className="remove" onClick={() => setSelectedOptions(selectedOptions.filter(o => o !== option))}>
                                x
                              </span>
                            </div>
                          ))}
                        </div>
                        */}
                      </div>
                      <div className="inf-table-wrapper flex-1" onScroll={handleScroll}>
                        <table className="inf-table w-full">
                          <thead className="text-sm text-neutral-400">
                            <tr className="border-b-2 border-neutral-300">
                              { !editing && <> 
                                <th scope="col"> Influencer </th> 
                                <th scope="col"> Profiles </th>
                                <th scope="col"> Location </th>
                                <th scope="col"> Followers </th>
                                <th scope="col"> Likes </th>
                              </> }
                              { editing && <>
                              <th scope="col" className="flex items-center space-x-2"> <input type={'checkbox'} className="w-4 h-4 border-neutral-800 rounded" onChange={handleSelectAll} checked={infIds.length === influencers.length}/> <span>Select all</span> </th> 
                              <th scope="col"> Remove </th> 
                              </> }
                            </tr>
                          </thead>
                          <tbody>
                            {fetchingLoading ? (
                              <tr>
                                <td colSpan="5" className="!py-10">
                                  <Spinner className="mx-auto" color="#CCCCCC" size={24} />
                                </td>
                              </tr>
                            )
                            : 
                            <>
                            { influencers.length > 0 ? 
                            <>
                            {influencers.map((inf, index) => {
                              const locationParts = inf.location ? inf.location.split(',') : [];
                              return (
                                <tr key={index} className={`${infId === inf.influencerid && 'active-inf'}`} onClick={() => { if(!editing) {setInfId(inf.influencerid) } }}>
                                  <td className="name">
                                    { editing && <input type={'checkbox'} className="w-5 h-5 border-neutral-800 rounded mr-3 ml-1" onChange={() => handleCheckboxChange(inf.influencerid)} checked={infIds.includes(inf.influencerid)}/>} <div>{inf.profileimage !== '' ? <img className="profile" src={`data:image/png;base64,${inf.profileimage}`} /> : <div className="img-placeholder"><span>{inf.name[0]}</span></div>}</div>
                                    <div>{inf.name}<br /><span>@{inf.instagram}</span></div>
                                  </td>
                                  { !editing && <> <td className="profiles">
                                    <div className="flex space-x-2 justify-center">
                                      {inf.tiktok !== '' && <img src="/images/icons/tiktok.png" />}
                                      {inf.instagram !== '' && <img src="/images/icons/instagram.png" />}
                                      {inf.youtube !== '' && <img src="/images/icons/youtube.png" />}
                                      {inf.twitch !== '' && <img src="/images/icons/twitch.png" />}
                                    </div>
                                  </td>
                                  <td className="location">{locationParts.length > 0 && <span>{locationParts[0]}</span>} {locationParts.length > 1 && <span>{locationParts[1]}</span>}</td>
                                  <td className="followers">{inf.tiktokfollowers}</td> 
                                  <td className="likes">{inf.tiktoklikes}</td>
                                  </> }
                                  { editing && <td className="remove"><TfiTrash className="mx-auto hover:text-red-500" size={20} onClick={() => handleDelete(inf.influencerid)}/></td> }
                                </tr>
                              )
                            })}
                            {loadingMore && (
                              <tr>
                                <td colSpan="5" className="!text-center !py-10">
                                  <Spinner color="#CCCCCC" size={24} />
                                </td>
                              </tr>
                            )}
                            </>
                            :
                            <tr>
                                <td colSpan="5" className="!text-center !py-10">
                                    <h6 className="text-base font-light text-neutral-400">No influencers in this list yet</h6>
                                </td>
                            </tr>
                            }
                            </>
                            }
                          </tbody>
                        </table>
                      </div>
                    </>
                  }
                </div>
              </div>
              {infId !== '' &&
                <div className={`w-2/6`}>
                <div class="inf-card">
                  { infLoading ? <div className="flex justify-center w-full py-12"> <Spinner color="#CCCCCC" size={24} /> </div> : 
                  <>
                  <div className="w-full flex flex-1 flex-col justify-between">
                  <div>
                  <div class="w-full flex justify-between relative">
                      <div>
                        { savedAlready == 1 || influencer.ifsaved == 1 ? 
                        <button class="btn-share flex items-center space-x-1" onClick={unsaveIt}>
                            <FaHeart size={12}/> <span>Saved</span>
                        </button>
                        :
                        <button class="btn-share flex items-center space-x-1" onClick={saveIt}>
                            <FaRegHeart size={12}/> <span>Save</span>
                        </button>
                        }
                      </div>
                      <div class="flex justify-end space-x-3">
                        { sharedAlready == 1 || influencer.ifshared == 1 ? 
                        <button class="btn-share flex items-center space-x-2" onClick={unshareWithTeam}>
                            <span>Shared with team</span> <TbChecks size={16}/>
                        </button>
                        :
                        <button class="btn-share flex items-center space-x-3" onClick={shareWithTeam}>
                            <FiUsers size={16}/> <span>Share with team</span>
                        </button>
                        }
                        <div className="relativex">
                          <div class={`btn-share flex items-center space-x-1 ${showListsDropdown && 'bg-hover'}`} onClick={() => setShowListsDropdown(!showListsDropdown)}>
                              <span>Save to list</span> {showListsDropdown ? <HiMiniChevronUp size={16}/> : <HiMiniChevronDown size={16}/>}
                          </div>
                          { showListsDropdown && 
                          <div className="save-list-dropdown">
                            {
                            lists.length > 0 ?
                            lists.map((list, index) => {
                              return(
                                listId != list.listid && <div key={index} className={`dropdown-item ${(index+1) == lists.length ? 'mb-2' : ''}`} onClick={() => saveToList(list.listid)}>{list.name}</div>
                              )
                            })
                            : 
                            <>
                            <div className="p-4">
                              <div className="italic font-normal text-neutral-500 text-[12px]">You don't have any lists</div>
                            </div>
                            </>
                            }
                            <div className="p-4 pt-0">
                              <hr className="mb-4"/>
                              <div className={`flex items-center font-normal text-sm pointer`} onClick={() => setshowNewModal(true)}><PiPlusCircle size={20} className="mr-1.5"/> <div>Create new list</div></div>
                            </div> 
                          </div> 
                          }
                        </div>
                      </div>
                  </div>
                  <div class="text-center mt-5">
                      <img src={`data:image/png;base64,${influencer.profileimage}`} alt="" class="profile"/>
                      <h5 class="title">{influencer.name}</h5>
                      <div className="flex justify-center space-x-3">
                        {(influencer && influencer.category) && ((influencer.category).split(', ').map((cat, index) => (
                          <span key={index} className="bg-[#f422ff] text-xs text-white rounded-full py-1.5 px-3 mb-3">{cat}</span>
                        )))}
                      </div>
                      {/* <h6 class="username"><a>@{influencer.instagram}</a></h6> */}
                  </div>
                  <div className="social-accounts w-11/12 ml-auto pl-3 mt-5">
                    <table className="w-full">
                      {influencer.tiktok != '' &&
                      <>
                      <tr>
                        <td colSpan={3}>
                          <Link to={`https://www.tiktok.com/@${influencer.tiktok}`} target="_blank" className="btn-social-link">
                            <img src="/images/icons/tiktok.png" className="absolute left-0 h-full"/>
                            <div>{influencer.tiktok}</div>
                            <LuArrowUpRight className="mx-auto" size={13}/>
                          </Link>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <h6>Likes</h6>
                            <h5>{influencer.tiktoklikes}</h5>
                          </div>
                        </td>
                        <td>
                            <div>
                              <h6>Followers</h6>
                              <h5>{influencer.tiktokfollowers}</h5>
                            </div>
                        </td>
                        <td>
                            <div>
                              <h6>Following</h6>
                              <h5>{influencer.tiktokfollowing}</h5>
                            </div>
                        </td>
                      </tr>
                      </>
                      }
                      {influencer.instagram != '' &&
                      <>
                      <tr>
                        <td colSpan={3}>
                          <Link to={`https://www.instagram.com/${influencer.instagram}`} target="_blank" className="mt-5 btn-social-link">
                            <img src="/images/icons/instagram.png" className="absolute left-0 h-full"/>
                            <div>{influencer.instagram}</div>
                            <LuArrowUpRight className="mx-auto" size={13}/>
                          </Link>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                              <h6>Posts</h6>
                              <h5>{influencer.instagramposts}</h5>
                          </div>
                        </td>
                        <td>
                            <div>
                              <h6>Followers</h6>
                              <h5>{influencer.instagramfollowers}</h5>
                            </div>
                        </td>
                        <td>
                            <div>
                              <h6>Following</h6>
                              <h5>{influencer.instagramfollowing}</h5>
                            </div>
                        </td>
                      </tr>
                      </>
                      }
                      {influencer.youtube != '' &&
                      <>
                      <tr>
                        <td colSpan={3}>
                          <Link to={`https://www.youtube.com/@${influencer.youtube}`} target="_blank" className="mt-5 btn-social-link">
                            <img src="/images/icons/youtube.png" className="absolute left-0 h-full"/>
                            <div>{influencer.youtube}</div>
                            <LuArrowUpRight className="mx-auto" size={13}/>
                          </Link>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                              <h6>Videos</h6>
                              <h5>{influencer.youtubevideos}</h5>
                          </div>
                        </td>
                        <td>
                            <div>
                              <h6>Views</h6>
                              <h5>{influencer.youtubeviews}</h5>
                            </div>
                        </td>
                        <td>
                            <div>
                              <h6>Subscribers</h6>
                              <h5>{influencer.youtubesubscribers}</h5>
                            </div>
                        </td>
                      </tr>
                      </>
                      }
                      {influencer.twitch != '' &&
                      <>
                      <tr>
                        <td colSpan={3}>
                          <Link to={`https://www.twitch.tv/${influencer.twitch}`} target="_blank" className="mt-5 btn-social-link">
                            <img src="/images/icons/twitch.png" className="absolute left-0 h-full"/>
                            <div>{influencer.twitch}</div>
                            <LuArrowUpRight className="mx-auto" size={13}/>
                          </Link>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                              <h6>Avg. Viewers</h6>
                              <h5>{influencer.twitchavgviews}</h5>
                          </div>
                        </td>
                        <td>
                            <div>
                              <h6>Followers</h6>
                              <h5>{influencer.twitchfollowers}</h5>
                            </div>
                        </td>
                        <td>
                            <div>
                              <h6>Subscribers</h6>
                              <h5>{influencer.twitchsubscribers}</h5>
                            </div>
                        </td>
                      </tr>
                      </>
                      }
                    </table>
                    {/*                     
                    <div className="flex flex-col space-y-5">
                      {influencer.tiktok != '' &&
                      <div>
                        <button className="p-2 pr-4 pl-3 bg-highlight rounded-full text-neutral-900 text-xs relative font-semibold flex justify-between items-center space-x-8">
                          <img src="/images/icons/tiktok.png" className="absolute left-0 h-full"/>
                          <div>{influencer.tiktok}</div>
                          <LuArrowUpRight className="mx-auto" size={13}/>
                        </button>
                        <div className="flex justify-between gap-x-5 mt-3">
                          <div className="w-1/3">
                            <div>
                              <h6>Likes</h6>
                              <h5>{influencer.tiktoklikes}</h5>
                            </div>
                          </div>
                          <div className="w-1/3 flex justify-center">
                            <div>
                              <h6>Followers</h6>
                              <h5>{influencer.tiktokfollowers}</h5>
                            </div>
                          </div>
                          <div className="w-1/3 flex justify-end">
                            <div>
                              <h6>Following</h6>
                              <h5>{influencer.tiktokfollowing}</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                      }
                      {influencer.instagram != '' &&
                      <div>
                        <button className="p-2 pr-4 pl-3 bg-highlight rounded-full text-neutral-900 text-xs relative font-semibold flex justify-between items-center space-x-8">
                          <img src="/images/icons/instagram.png" className="absolute left-0 h-full"/>
                          <div>{influencer.instagram}</div>
                          <LuArrowUpRight className="mx-auto" size={13}/>
                        </button>
                        <div className="flex justify-between gap-x-5 mt-3">
                          <div className="w-1/3">
                            <div>
                              <h6>Posts</h6>
                              <h5>{influencer.instagramposts}</h5>
                            </div>
                          </div>
                          <div className="w-1/3 flex justify-center">
                            <div>
                              <h6>Followers</h6>
                              <h5>{influencer.instagramfollowers}</h5>
                            </div>
                          </div>
                          <div className="w-1/3 flex justify-end">
                            <div>
                              <h6>Following</h6>
                              <h5>{influencer.instagramfollowing}</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                      }
                      {influencer.youtube != '' &&
                      <div>
                        <button className="p-2 pr-4 pl-3 bg-highlight rounded-full text-neutral-900 text-xs relative font-semibold flex justify-between items-center space-x-8">
                          <img src="/images/icons/youtube.png" className="absolute left-0 h-full"/>
                          <div>{influencer.youtube}</div>
                          <LuArrowUpRight className="mx-auto" size={13}/>
                        </button>
                        <div className="flex justify-between gap-x-5 mt-3">
                          <div className="w-1/3">
                            <div>
                              <h6>Videos</h6>
                              <h5>{influencer.youtubevideos}</h5>
                            </div>
                          </div>
                          <div className="w-1/3 flex justify-center">
                            <div>
                              <h6>Views</h6>
                              <h5>{influencer.youtubeviews}</h5>
                            </div>
                          </div>
                          <div className="w-1/3 flex justify-end">
                            <div>
                              <h6>Subscribers</h6>
                              <h5>{influencer.youtubesubscribers}</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                      }
                      {influencer.twitch != '' &&
                      <div>
                        <button className="p-2 pr-4 pl-3 bg-highlight rounded-full text-neutral-900 text-xs relative font-semibold flex justify-between items-center space-x-8">
                          <img src="/images/icons/twitch.png" className="absolute left-0 h-full"/>
                          <div>{influencer.twitch}</div>
                          <LuArrowUpRight className="mx-auto" size={13}/>
                        </button>
                        <div className="flex justify-between gap-x-5 mt-3">
                          <div className="w-1/3">
                            <div>
                              <h6>Average Viewers</h6>
                              <h5>{influencer.twitchavgviews}</h5>
                            </div>
                          </div>
                          <div className="w-1/3 flex justify-center">
                            <div>
                              <h6>Followers</h6>
                              <h5>{influencer.twitchfollowers}</h5>
                            </div>
                          </div>
                          <div className="w-1/3 flex justify-end">
                            <div>
                              <h6>Subscribers</h6>
                              <h5>{influencer.twitchsubscribers}</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                      }
                    </div> */}
                  </div>
                  {/*                   
                  <div class="boxes grid grid-cols-2">
                    <div>
                      <div>
                        <div>
                            <h5>{influencer.tiktoklikes}</h5>
                            <h6>Likes</h6>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div>
                        <div>
                            <h5>{influencer.tiktokfollowers}</h5>
                            <h6>Followers</h6>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div>
                        <div>
                            <h5>{influencer.tiktokfollowing}</h5>
                            <h6>Following</h6>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div>
                        <div>
                            <h5><LuArrowUpRight className="mx-auto" size={26}/></h5>
                            <h6>Visit full profile</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="flex justify-center">
                      { sharedAlready == 1 || influencer.ifshared == 1 ? 
                      <button class="mx-auto btn-share flex items-center space-x-2">
                          <span>Shared with Team</span> <TbChecks size={16}/>
                      </button>
                      :
                      <button class="mx-auto btn-share flex items-center space-x-3" onClick={shareWithTeam}>
                          <FiUsers size={16}/> <span>Share profile with team</span>
                      </button>
                      }
                  </div>
                   */}
                  </div>
                  {/* <div class="mt-5 other-profiles">
                      <div>Explore their other profiles</div>
                      <div class="mt-3">
                        <div className="flex space-x-3 justify-center">
                          {influencer.tiktok != '' && <img src="/images/icons/tiktok.png"/>}
                          {influencer.instagram != '' && <img src="/images/icons/instagram.png"/>}
                          {influencer.youtube != '' && <img src="/images/icons/youtube.png"/>}
                          {influencer.twitch != '' && <img src="/images/icons/twitch.png"/>}
                        </div>
                      </div>
                  </div> */}
                  </div>
                  </>
                  }
              </div>
                </div>
              }
            </div>
          </div>
        )}
      </section>
    </AppLayout>
  );
};

export default Lists;
